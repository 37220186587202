import React from 'react';

import classes from './SmallSpinnerDark.module.css';

const SmallSpinnerDark = (props) => (
    <div className={classes.Loader}>Loading...</div>
);


  
export default SmallSpinnerDark;