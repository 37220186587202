import React from 'react';

import classes from './SpinnerDark.module.css';

const SpinnerDark = (props) => (
    <div className={classes.Loader}>Loading...</div>
);


  
export default SpinnerDark;