import React, { useState } from 'react'
import QrReader from 'react-qr-reader';
import classes from './QRScanner.module.css';
 
const QRScanner = () =>  {
    const [data, setData] = useState();
 
    const handleScan = (data) => {
        if (data) {
            
            setData(data);
        }
    }

    if (data) {
        if (data.includes('www.') || (data.includes('http'))) {
            window.location.replace(data);
        } 
    }

    const handleError = (err) => {
        console.error(err)
    }
    return (
      <div className={classes.QRScanner}>
        <QrReader
          delay={300}
          onError={handleError}
          onScan={handleScan}
          style={{ width: '100%' }}
        />
        <p>{'QR code data: ' + data}</p>
      </div>
    );
}

export default QRScanner;