import {React, Fragment, useState, useEffect, useCallback, useRef} from 'react';
import classes from './CentreLink.module.css';
import { handleAwsApiError, getPrevMonday, 
    // getNextDay, 
    // downloadPDFFromBase64 
    handleBannerText, useOnScreen, incrementDate, calculateCentrelinkWeek, handleBSTableColourCoding, handlePrompt, 
    // getURLSubroute, 
    // arrayObjsToCsv
} from '../../../utilities/functions';
import Banner from '../../../UI/Banner/Banner';
import Modal from '../../../UI/Modal/Modal';
import { useHistory, useLocation } from "react-router-dom";
import axios from 'axios';
import { AWS_API, centrelinkMon1, cognitoClientId, cognitoUserpoolId, timezone} from '../../../utilities/globalVariables';
// import SpinnerDark from '../../../UI/SpinnerDark/SpinnerDark';
import SmallSpinnerDark from '../../../UI/SmallSpinnerDark/SmallSpinnerDark';
import BSTable from '../../../UI/BSTable/BSTable';
import AdditionalParams from '../../Misc/AdditionalParams/AdditionalParams';
import ParamsForm from '../../../UI/ParamsForm/ParamsForm';
import ISInnovativeCases from './ISInnovativeCases/ISInnovativeCases';
import ISInnovativeCaseClaims from './ISInnovativeCaseClaims/ISInnovativeCaseClaims';
import ISMessages from './ISMessages/ISMessages';
import ISPayments from './ISPayments/ISPayments';
import { bootstrapTableConfig } from '../../../utilities/globalObjects';
// import { bootstrapTableConfig } from '../../../utilities/globalObjects';

// icons
import {AiOutlineStop} from "react-icons/ai";
import {TiTickOutline} from "react-icons/ti";
import {IoRefreshOutline} from "react-icons/io5";
import {FiSettings} from "react-icons/fi";
import BackdropSpinner from '../../../UI/BackdropSpinner/BackdropSpinner';
import DirectDebitStatus from '../../Misc/DirectDebitStatus/DirectDebitStatus';
import {cloneDeep} from 'lodash';
import Prompt from '../../../UI/Prompt/Prompt';


// import SpinnerDark from '../../../UI/SpinnerDark/SpinnerDark';

// config for data and detailed data based on content selected
const dataMap = {
    pendingISCaseClaims: {
        data: 'pending_is_case_claim_submissions',
        detailData: 'pending_is_case_claim_submissions_detail',
        filterBy: [{conditionCol: 'week_commencing', conditionValue: 'selectedWeekCommencing'}]
    }, 
    pendingSessionReports: {
        data: 'view_pending_session_reports_time_limited',
        // detailData: 'view_session_report_check'
        detailData: 'view_pending_session_reports_detail',
        filterBy: [{conditionCol: 'week_commencing', conditionValue: 'selectedWeekCommencing'}]
    },
    // lateRollChanges: {
        //     data: 'view_roll_changes_after_submission',
    //     filterBy: [{conditionCol: 'week_commencing', conditionValue: 'selectedWeekCommencing'}]
    // },
    viewIsCases: {
        data: 'view_is_cases',
        detailData: 'is_case_children'
    },
    submittedISCaseClaims: {
        data: 'view_submitted_is_claims',
        filterBy: [{conditionCol: 'week_commencing', conditionValue: 'selectedWeekCommencing'}],
        autoFilter: {column: 'week_commencing'}
    },
    submittedSessionReports: {
        // data: 'view_submitted_session_reports',
        // detailData: 'view_submitted_session_reports_detail',
        data: 'view_submitted_session_reports_v2',
        detailData: 'view_submitted_session_reports_detail_v2',
        filterBy: [{conditionCol: 'week_commencing', conditionValue: 'selectedWeekCommencing'}],
        autoFilter: {column: 'week_commencing'}
    },
    amendedSessionReports: {
        data: 'view_late_created_session_reports',
        detailData: 'view_submitted_session_reports_detail_v2',
        filterBy: [{conditionCol: 'created_at_str', conditionValue: 'selectedWeekCommencing'}]
    },
    // amendedSessionReports: {
    //     data: 'view_amended_session_reports',
    //     // detailData: 'view_session_report_check'
    //     detailData: 'view_amended_session_reports_detail',
    //     filterBy: [{conditionCol: 'updated_week_commencing', conditionValue: 'selectedWeekCommencing'}]
    // },
    centrelinkPayments: {
        data: 'view_session_report_payments',
        detailData: 'cl_payment_items',
        filterBy: [{conditionCol: 'period_start', conditionValue: 'selectedWeekCommencing'}],
        autoFilter: {column: 'period_start'}
    },
    pendingInvoices: {
        data: 'view_pending_invoices',
        detailData: 'view_pending_invoices_detail',
        filterBy: [{conditionCol: 'period_start', conditionValue: 'selectedWeekCommencing'}],
        autoFilter: {column: 'period_start'},
        periodLengthDays: 13
    }, 
    invoices: {
        data: 'view_invoices',
        detailData: 'invoice_items',
        filterBy: [{conditionCol: 'period_start', conditionValue: 'selectedWeekCommencing'}],
        autoFilter: {column: 'period_start'},
        periodLengthDays: 13
    }, 
    openInvoicesCheck: {
        data: 'view_accounts_multiple_open_invoices',
        detailData: 'view_open_invoices',
    }, 
    generalActionsRequired: {
        data: 'view_general_actions_required',
    }, 
};

// const weekCommencingAutoFilterTables = {
//     pending_is_case_claim_submissions: {column: 'week_commencing'},
//     pending_session_reports: {column: 'week_commencing'},
//     // view_submitted_session_reports: {column: 'week_commencing'}, 
//     view_submitted_session_reports_v2: {column: 'week_commencing'}, 
//     view_amended_session_reports: {column: 'updated_week_commencing'}, 
//     view_submitted_is_claims: {column: 'week_commencing'}, 
//     view_roll_changes_after_submission: {column: 'week_commencing'}, 
//     view_invoices: {column: 'period_start', periodLengthDays: 13},
//     view_pending_invoices: {column: 'period_start', periodLengthDays: 13},
//     view_session_report_payments: {column: 'period_start'},
// };

const fortnightlyShowContentTypes = ['pendingInvoices', 'invoices'];

const CentreLink = (props) => {

    // infinite scroll detection
    const ref = useRef()
    const isVisible = useOnScreen(ref)
    
    // UI state
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [bannerText, setBannerText] = useState('');       
    const [prompt, setPrompt] = useState({});      
    const [showContent, setShowContent] = useState()
    const [dataRefreshToggle, setDataRefreshToggle] = useState(false);
    // const [showInclusionSupportOptions, setShowInclusionSupportOptions] = useState();
    
    // return fee reduction state
    const [showReturnFeeModal, setShowReturnFeeModal] = useState();
    const [returnFeePostData, setReturnFeePostData] = useState();

    // submit IS Case Claim state
    const [showSubmitCaseClaimModal, setShowSubmitCaseClaimModal] = useState();
    const [submitCaseClaimData, setSubmitCaseClaimData] = useState();
    
    // additionalParams state
    const [additionalParams
        , setAdditionalParams
    ] = useState();
    const [showAdditionalParams, setShowAdditionalParams] = useState();
    
    // table state passed to child component (held in parent to prevent re-rendering issues)
    const [expanded, setExpanded] = useState([]);
    const [selected, setSelected] = useState([]);  

    // data state
    const [data, setData] = useState();
    const [detailData, setDetailData] = useState();
    const [centres, setCentres] = useState();
    const [awsSecrets, setAwsSecrets] = useState();
    const [directDebitData ] = useState();
    const [alternativeBillingArrangementData] = useState();
    // const [menuItemBadgeCounts, setMenuItemBadgeCounts] = useState();


    // week state
    const [selectedWeekCommencing, setSelectedWeekCommencing] = useState(new Date(getPrevMonday(new Date())));

    // set week commencing/starting dates
    // const weekStarting = getPrevMonday(new Date());
    // const weekEnding = getNextDay(0, new Date()); // 0 = sunday

    // state to indicate the parent component should render a child component rather than display data directly in this component
    const [showComponent ,setShowComponent] = useState();
    
    // react router history instance to navigate user to other pages
    let history = useHistory();   
    const location = useLocation();

    // pagination state
    const [tableLoading, setTableLoading] = useState();
    const [pageSize] = useState(2000);
    const [pageNumber, setPageNumber] = useState();
    const [reachedEndOfTable, setReachedEndOfTable] = useState(false);

    // console logs
    // console.log('pageNumber: ', pageNumber);
    // console.log('additionalParams: ', additionalParams);
    // console.log('selectedWeekCommencing: ', selectedWeekCommencing);
    // console.log('awsSecrets: ', awsSecrets);
    console.log('data: ', data);
    // console.log('directDebitData: ', directDebitData);
    // console.log('alternativeBillingArrangementData: ', alternativeBillingArrangementData);
    console.log('dataRefreshToggle: ', dataRefreshToggle);

    // get data from props
    const setSublinks = props.setSublinks;

    const handleDisplayContent = useCallback((show) => {

        
        // check if content has changed
        // if (show === showContent) {
        //     console.log('show === showContent, set with timer')
        //     setTimeout(() => {
        //         setShowContent(show);
        //     }, 200)
        // } else {
        //     setShowContent(show);
            
        // }

        
        setShowContent(prevShowContent => {
            if (show === prevShowContent) {
                // void(0);
                console.log('show === showContent, leaving unchanged')
                return prevShowContent
                // setTimeout(() => {
                    //     return show
                    // }, 200)
            } else {
                // reset data, and pagination and set selected content type
                resetDataState();
                setLoading(true);
                return show
            
            }            
        })
        // getMasterData(dataMap[show].data, setData, true, true, 1, pageSize);

    }, []);  

    // set leftpane sublinks
    useEffect(() => {

        // // version using subrouting
        // const newSublinks = [
        //     {title: 'Pre-Submissions Checklist', icon: 'question',  onClick: () => history.replace('/centrelink/pendingSessionReports')},
        //     {title: 'Session Reports', icon: 'question', onClick: () => history.replace('/centrelink/submittedSessionReports')},
        //     {title: 'Late Roll Changes', icon: 'question', onClick: () => history.replace('/centrelink/lateRollChanges')},
        //     {title: 'Payments', icon: 'question', onClick: () => history.replace('/centrelink/centrelinkPayments')},
        //     {title: 'Pre-Invoices Checklist', icon: 'question', onClick: () => history.replace('/centrelink/pendingInvoices')},
        //     // {title: 'Invoices', icon: 'question', onClick: () => handleDisplayContent('invoices')},
        //     {title: 'Invoices', icon: 'question', onClick: () => history.replace('/centrelink/invoices')},
        //     {title: 'Open Invoices Check', icon: 'question', onClick: () => history.replace('/centrelink/openInvoicesCheck')},
        //     {title: 'IS Cases', collapsed: true, type: 'outline-secondary', onClick: () => history.replace('/centrelink/viewIsCases')},
        //     {title: 'Upcoming Case Claims', collapsed: true, type: 'outline-secondary', onClick: () => history.replace('/centrelink/pendingISCaseClaims')},
        //     {title: 'Submitted Case Claims', collapsed: true, type: 'outline-secondary', onClick: () => history.replace('/centrelink/submittedISCaseClaims')},
        //     {title: 'Innovative Cases', collapsed: true, type: 'outline-secondary', onClick: () => handleDisplayComponent('isInnovativeCases')},
        //     {title: 'Innovative Case Claims', collapsed: true, type: 'outline-secondary', onClick: () => handleDisplayComponent('isInnovativeCaseClaims')},
        //     {title: 'IS Messages', collapsed: true, type: 'outline-secondary', onClick: () => handleDisplayComponent('isMessages')},
        //     {title: 'IS Payments', collapsed: true, type: 'outline-secondary', onClick: () => handleDisplayComponent('isPayments')},
        // ];

        // object to store links to pass up to App.js and render through Layout.js
        const newSublinks = [
            {title: 'Pre-Submissions Checklist', icon: 'question',  onClick: () => handleDisplayContent('pendingSessionReports')},
            {title: 'Session Reports', icon: 'question', onClick: () => handleDisplayContent('submittedSessionReports')},
            {title: 'Session Report Amendments', icon: 'question',  onClick: () => handleDisplayContent('amendedSessionReports')},
            // {title: 'New Session Reports', icon: 'question',  onClick: () => handleDisplayContent('newCreatedSessionReports')},
            // {title: 'Late Roll Changes', icon: 'question', onClick: () => handleDisplayContent('lateRollChanges')},
            {title: 'Payments', icon: 'question', onClick: () => handleDisplayContent('centrelinkPayments')},
            {title: 'Pre-Invoices Checklist', icon: 'question', onClick: () => handleDisplayContent('pendingInvoices')},
            {title: 'Invoices', icon: 'question', onClick: () => handleDisplayContent('invoices')},
            {title: 'Open Invoices Check', icon: 'question', onClick: () => handleDisplayContent('openInvoicesCheck')},
            {title: 'Actions Required', icon: 'question', onClick: () => handleDisplayContent('generalActionsRequired')},
            {title: 'IS Cases', collapsed: true, type: 'outline-secondary', onClick: () => handleDisplayContent('viewIsCases')},
            {title: 'Upcoming Case Claims', collapsed: true, type: 'outline-secondary', onClick: () => handleDisplayContent('pendingISCaseClaims')},
            {title: 'Submitted Case Claims', collapsed: true, type: 'outline-secondary', onClick: () => handleDisplayContent('submittedISCaseClaims')},
            {title: 'Innovative Cases', collapsed: true, type: 'outline-secondary', onClick: () => handleDisplayComponent('isInnovativeCases')},
            {title: 'Innovative Case Claims', collapsed: true, type: 'outline-secondary', onClick: () => handleDisplayComponent('isInnovativeCaseClaims')},
            {title: 'IS Messages', collapsed: true, type: 'outline-secondary', onClick: () => handleDisplayComponent('isMessages')},
            {title: 'IS Payments', collapsed: true, type: 'outline-secondary', onClick: () => handleDisplayComponent('isPayments')},
        ];
        // {title: 'Documents', icon: 'question', onClick: () => handleDisplayContent('documents')},
        
        // newSublinks.push({title: raw[key] ?? key, badge: pendingFormCounts[key], onClick: () => {setShowFormType(key); setSelectedFormObj();}})
        setSublinks(newSublinks);

    }, [setSublinks, handleDisplayContent, history])      
    
    // // get count of actions required
    // useEffect(() => {

    //     const getActionsRequiredCount = async () => {

    //         // set up object with params for final lambda function and variables for lambda authorizer on API Gateway
    //         const config = {
    //             headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
    //             params: {tableName: 'view_general_actions_required'}
    //         };
            
    //         // call lambda function to get master data
    //         try {
    //             const actionsRequiredRes = await axios.get(AWS_API + route, config);
    //             console.log('data fetch res: ', res.data);
    //             stateFn(res.data);
    //         } catch (err) {
    //             setError(handleAwsApiError(err, history, location) ?? 'Error encountered while fetching master data');
    //         }                

    //     }



    // }, []);


    // set content based on subroute
    // useEffect(() => {
    //     // get subroute and set content
    //     const subroute = getURLSubroute(location);
    //     console.log('subroute: ', subroute);
    //     if (subroute) {
    //         handleDisplayContent(subroute);
    //     }

    // }, [location, handleDisplayContent]);

    // get centres data to pass to children components
    const getCentresData = useCallback(async () => {
        
        // setLoading(true);
    
        // set up object with params for final lambda function and variables for lambda authorizer on API Gateway
        const config = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
            params: {table: 'centres'}
        };
        
        // call lambda function to get master data
        try {
            const res = await axios.get(AWS_API + 'master-data/fetch', config);
            console.log('data fetch res: ', res.data);
            setCentres(res.data.filter(obj => obj.service_crn) ?? []); // only store centres with a CRN for now
        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Error encountered while fetching master data');
        }            

        // setLoading(false);

    }, [history, props, location]);    

    // get initial centres data
    useEffect(() => {
        getCentresData();
    }, [getCentresData])

    // set page number to 1 on component load
    useEffect(() => {

        // if page number not been set, set to 1
        if (!pageNumber) {
            // setTimeout(() => {
                setPageNumber(1);            

            // }, 200);
        }
    }, [pageNumber])


    
    // update page number whenever inf scroll component reached
    useEffect(() => {
        console.log('isVisible change to: ', isVisible);
        if (isVisible) {
            // increment page number by one
            // setPageNumber(prevNum => !prevNum ? 2 : prevNum + 1);            
            setPageNumber(prevNum => prevNum + 1);            
        }
    }, [isVisible])
    
    // whenever page number is increased, fetch more data
    useEffect(() => {

        const getMoreData = async (conditionCol, conditionValue) => {
            setTableLoading(true);

            // get additional data
            const config = {
                headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
                params: {table: dataMap[showContent]?.data, pageSize: pageSize, pageNumber: pageNumber}
            };

            if (conditionCol && conditionValue) {
                config.params.conditionCol = conditionCol;
                config.params.conditionValue = conditionValue;
            }
            
            // call lambda function to get master data
            try {
                const res = await axios.get(AWS_API + 'master-data/fetch', config);
                console.log(`[Centrelink.js] - fetched more data - pagesize: ${pageSize}, pagenumber: ${pageNumber}, conditionCol: ${conditionCol}, conditionValue: ${conditionValue}`);
                
                // if there was still more data to ferch, save to state
                if (res.data.length > 0) {
                    setData(prevData => {
                        // console.log('prevData: ', prevData);
                        
                        if (prevData?.length > 0) {
                            return [...prevData, ...res.data]
                        } else {
                            return res.data
                        }
                    });
                
                // if not, set reachedEndOfTable to true
                } else {
                    setReachedEndOfTable(true);
                    console.log(`[Centrelink.js] - marked reachedEndOfTable as true`)
                }
            } catch (err) {
                setError(handleAwsApiError(err, history, location) ?? 'Error encountered while fetching master data');
            }    

            setTableLoading(false);
            setLoading(false);
        }

        if (showContent && pageNumber >= 1 && !reachedEndOfTable) {

            // check for filter conditions
            const filters = dataMap[showContent]?.filterBy?.[0];
            const conditionCol = filters?.conditionCol;
            let conditionValue = filters?.conditionValue;

            // assign variable condition values
            if (conditionValue === 'selectedWeekCommencing') conditionValue = selectedWeekCommencing.toLocaleDateString("sv-SW", {timeZone: timezone});

            getMoreData(conditionCol, conditionValue);
        } else {
            console.log(`[Centrelink.js] - didn't fetch more data, either no showContent set (${showContent}), page number < 1 (${pageNumber}) or reachedEndOfTable true (${reachedEndOfTable}) `);
        }
    }, [pageNumber, history, pageSize, props.token, showContent, reachedEndOfTable, selectedWeekCommencing, location, dataRefreshToggle])

    // function to reset state relating to data, pagination etc
    const resetDataState = () => {
        setData();
        // setDirectDebitData();
        setDetailData();
        setPageNumber(1);
        setReachedEndOfTable(false);
        setSelected([]);
    }

    // when selected week view changed, reset data 
    const handleChangeSelectedWeekCommencing = useCallback((initialDate, setStateFunction, sign, forceDayDiff, setToday) => {

        // overwrite daydiff if specified
        let dayDiff = 7;
        if (forceDayDiff) {
            // override 
            dayDiff = forceDayDiff;
        } else if (fortnightlyShowContentTypes.includes(showContent)) {
            // set daydiff based on content type
            dayDiff = 14;

        }

        // set direction
        dayDiff = dayDiff * sign;

        // reset data and page number
        resetDataState();

        // either set date back to this week or increment as required
        if (setToday) {
            setSelectedWeekCommencing(new Date(getPrevMonday(new Date())));
        } else {
            incrementDate(initialDate, dayDiff, setStateFunction);
        }

    }, [showContent]);

    
    const getMasterData = useCallback(async (tableName, stateFn, firstCall, lastCall, pageNumber, pageSize, conditionCol, conditionValue) => {
        
        console.log(`fetching data for table ${tableName} with page size ${pageSize} and page number ${pageNumber} `);

        firstCall ? setLoading(true) : void(0);

        if (tableName) {
            // set up object with params for final lambda function and variables for lambda authorizer on API Gateway
            const config = {
                headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
                params: {table: tableName, pageSize: pageSize, pageNumber: pageNumber, conditionCol: conditionCol, conditionValue: conditionValue}
            };
            
            // call lambda function to get master data
            try {
                const res = await axios.get(AWS_API + 'master-data/fetch', config);
                console.log('[centrelink.js] - data fetch res: ', res.data);
                stateFn(res.data);
            } catch (err) {
                setError(handleAwsApiError(err, history, location) ?? 'Error encountered while fetching master data');
            }            

        }

        lastCall ? setLoading(false) : void(0);
    }, [history, props, location]);

    // generic function to get data and save to state
    const genericFetchFunction = useCallback(async (route, params, stateFn) => {
        
        // firstCall ? setLoading(true) : void(0);

        // set up object with params for final lambda function and variables for lambda authorizer on API Gateway
        const config = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
            params: params
        };
        
        // call lambda function to get master data
        try {
            const res = await axios.get(AWS_API + route, config);
            console.log('data fetch res: ', res.data);
            stateFn(res.data);
        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Error encountered while fetching master data');
        }            


        // lastCall ? setLoading(false) : void(0);

    }, [history, props.token, location]);      

    // set default option for content display
    useEffect(() => {
        // const show = 'pendingSessionReports'; 
        const show = 'submittedSessionReports'; 
        // getMasterData(dataMap[show].data, setData, true, true, 1, pageSize);
        setShowContent(show);
        console.log('defaulted showContent to:  ', show);
    }, [getMasterData, pageSize])

    // get optional data for specific routes
    useEffect(() => {

        // for pending invoices, get relevant xero secrets and direct debit statuses
        if (showContent === 'pendingInvoices') {

            // get xero secrets
            genericFetchFunction('master-data/fetch-secrets', {vault: 'xero'}, setAwsSecrets);

            // temporarily removing direct debit fetch due to volume constraints
            // fetch direct debit mandates for each of these emails
            // if (data?.length > 0) {
            //     const emails = data.map(obj => obj.account_email).filter((x, i, a) => a.indexOf(x) === i);
            //     // console.log('getting direct debit status for pending invoice emails: ', emails);
            //     if (emails?.length > 0) {
            //         genericFetchFunction('master-data/direct-debit-status', {emails: JSON.stringify(emails)}, setDirectDebitData);
            //         getMasterData('view_alternative_billing_arrangements', setAlternativeBillingArrangementData, false, false);                    

            //     }

            // }


        } 
    }, [showContent, genericFetchFunction, data, getMasterData])    

    // update date for methods that require a certain centrelink week
    useEffect(() => {

        

        // for pending invoices, get relevant xero secrets and direct debit statuses
        if (fortnightlyShowContentTypes.includes(showContent)) {
            const clWeek = calculateCentrelinkWeek(selectedWeekCommencing, centrelinkMon1);
            // console.log('[test] - clweek: ', clWeek);
            if (clWeek !== 1) {
                // console.log('Not in week 1, incrementing date');
                handleChangeSelectedWeekCommencing(selectedWeekCommencing, setSelectedWeekCommencing, -1, 7);
            }
        
        }







        
    }, [showContent, handleChangeSelectedWeekCommencing, selectedWeekCommencing])    



    // function used to set state to indicate the CentreLink.js will show a child component rather than data directly rendered from this component
    const handleDisplayComponent = (component) => {
        setShowComponent(component);
        setShowContent();
    } 

    

    // function to write to submission review table in rds
    const postSubmissionReview = async (ids, status) => {
        setLoading(true);

        // get values based on selected show content
        const submissionType = dataMap[showContent].data;
        console.log('submissionType: ', submissionType);

        // get rows relating to id
        const uniqueSelectIdField = bootstrapTableConfig[submissionType]?.uniqueSelectId;
        console.log('uniqueSelectIdField: ', uniqueSelectIdField)
        const rowsToSend = data.filter(obj => ids.includes(obj[uniqueSelectIdField]));
        console.log('rowsToSend: ', rowsToSend);


        const headers = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
        };
        const postData = {
            rows: rowsToSend,
            submissionType: submissionType,
            status: status
        };

        console.log('postData: ', postData);
        try {
            const res = await axios.post(AWS_API + 'master-data/record-cl-submission-review', {postData: postData}, headers);
            console.log('res: ', res.data);
            handleBannerText(setBannerText, `Submitted`);
            setSelected([]);
            
            // update master data
            getMasterData(dataMap[showContent].data, setData, true, true);
        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Unexpected error encountered while submitting centrelink review data');
            console.log('error whilst submitting centrelink review data: ', err);
            setLoading(false);
        }          
    }

    // function to handle front-end interaction with CCS services via app/centrelink lambda middleman
    const postToAppCentrelinkLambda = async (route, ids, actionType) => {
        setLoading(true);

        // warnings to display based on current date vs selected date
        if (route === 'run-step-create-invoices-for-selection') {

            // check period has finished
            const weekEndTime = new Date(weekEnding).getTime();
            const currentTime = new Date().getTime();
            
            if (currentTime < weekEndTime) {
                setError('Invoice engine can not be run before selected period has ended')
                setLoading(false);
                return false;
            }

        }

        // get values based on selected show content
        const table = dataMap[showContent].data;
        console.log('table: ', table);

        // get rows relating to id
        const uniqueSelectIdField = bootstrapTableConfig[table]?.uniqueSelectId;
        console.log('uniqueSelectIdField: ', uniqueSelectIdField)
        
        
        // get rows relating to id
        let rowsToSend;
        if (ids) {
            rowsToSend = data.filter(obj => ids.includes(obj[uniqueSelectIdField]));
            console.log('rowsToSend: ', rowsToSend);
            // rowsToSend = data.filter(obj => ids.includes(obj.id));
            // console.log('rowsToSend: ', rowsToSend);
        }
        console.log('rowsToSend: ', rowsToSend);


        const headers = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
        };
        const postData = {
            rows: rowsToSend,
            actionType: actionType,
            additionalParams: additionalParams
        };

        console.log('postData: ', postData);
        try {
            const res = await axios.post(AWS_API + 'ccs/' + route, {postData: postData}, headers);
            console.log('res: ', res?.data);
            
            setLoading(false);
            handleBannerText(setBannerText, `Request submitted`);
            
            // update master data
            // getMasterData(dataMap[showContent].data, setData, false, false);
        } catch (err) {
            setLoading(false);
            setError(handleAwsApiError(err, history, location) ?? 'Unexpected error encountered while submitting centrelink review data');
            console.log('error whilst submitting centrelink review data: ', err);
        }  
    }

    const postCCSGenericWrite = async (data, serviceCRN, routeToInvoke, ismsFlag) => {

        console.log('posting return fee reduction with data: ', data);
        
        setLoading(true);
        
        // close modals
        setShowReturnFeeModal(false);

        const headers = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
        };
        const postData = {
            data: data,
            serviceCRN: serviceCRN,
            routeToInvoke: routeToInvoke,
        };
        
        // remove fields shown to user for validation but not to be submitted
        if (postData.data.childName) {
            delete postData.data.childName;
        }

        // stringify fields which needed to be integers to add correctly and show on form
        if (postData.data.totalFeeReductionAmountForWeek) {
            postData.data.totalFeeReductionAmountForWeek = postData.data.totalFeeReductionAmountForWeek.toString();
        }

        console.log('postData: ', postData);
        try {
            const appCLCallsRoute = ismsFlag ? 'generic-write-isms' : 'generic-write';
            const res = await axios.post(AWS_API + 'ccs/' + appCLCallsRoute, {postData: postData}, headers);
            console.log('res: ', res?.data);
            handleBannerText(setBannerText, `Data submitted`);
            
        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Unexpected error encountered while submitting CCS data');
            console.log('error whilst posting to generic write CCS: ', err);
        }    

        // clear data
        setReturnFeePostData();
        
        setLoading(false);
    }

    // const handleCancelIsCaseClaim = (ids) => {

    //     // get rows relating to id
    //     let row;
    //     if (ids.length === 1) {
    //         row = data.filter(obj => ids.includes(obj.id))[0];
    //         console.log('rowsToSend: ', row);
    //     } else {
    //         alert('please select just one case claim to cancel');
    //     }

    //     const postData = {
    //         isCaseId: row.is_case_id,
    //         isCaseClaimId: row.is_case_claim_id,
    //     };

    //     console.log('data: ', postData);
    //     postCCSGenericWrite(postData, row.service_crn, 'cancel-is-case-claim', true);
    // }
  

    const handleWithdrawSessionReport = (ids) => {

        // get rows relating to id
        let row;
        if (ids.length === 1) {
            row = data.filter(obj => ids.includes(obj.id))[0];
            console.log('rowsToSend: ', row);
        } else {
            alert('please select just one session report to withdraw')
        }

        const postData = {
            enrolmentID: row.ccs_enrolment_id,
            sessionReportStartDate: row.week_commencing,
            ...additionalParams
        };

        console.log('data: ', postData);
        postCCSGenericWrite(postData, row.service_crn, 'withdraw-session-report')
    }

    // function to get detailed data to show when a selected row is expanded in bootstrap table
    const getDetailData = (col, val) => {
        console.log('[CentreLink.js] - getting detailed data for id column and value: ', col, val);

        // fetch detailed data for selected row, without causing whole to show spinner
        if (dataMap[showContent].detailData) {
            getMasterData(dataMap[showContent].detailData, setDetailData, false, false, null, null, col, val);
        } else {
            console.log('no detailed data specified');
            setDetailData([{'no drilldown data specified': 'no drilldown data specified'}]);
        }        
        
    }

    const clearDetailData = () => {
        setDetailData();
    }

    const handleSubmitCaseClaim = async (data) => {

        setLoading(true);

        if (selected.length !== 1) {
            alert('Please only submit one case claim at a time thorugh this interface');
            return false;
        }
        
        console.log('data: ', data);        
        
        // close modals
        setShowSubmitCaseClaimModal(false);

        const headers = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
        };
        const postData = {
            ...data
        };
        
        console.log('postData: ', postData);
        try {
            const res = await axios.post(AWS_API + 'ccs/submit-is-claims', {postData: postData}, headers);
            console.log('res: ', res?.data);
            handleBannerText(setBannerText, `Data submitted`);
            
        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Unexpected error encountered while submitting CCS data');
            console.log('error whilst posting to generic write CCS: ', err);
        }    

        // clear data
        setSubmitCaseClaimData();
        
        setLoading(false);        
    }

    // const getStatementPdf = async (invoiceIds) => {
        
    //     setLoading(true);
        

    //     // get invoice selected and return error if more than one selectd
    //     let invoiceId;
    //     if (invoiceIds.length > 1) {
    //         alert('Please select only one statement');
    //         setLoading(false);
    //         return false;
    //     } else {
    //         invoiceId = invoiceIds[0];
    //     }
        
    //     const selectedInvoice = data.filter(obj => obj.invoice_id === invoiceId)[0];
    //     console.log('selectedInvoice: ', selectedInvoice);
    //     const periodEnd = selectedInvoice.period_end.slice(0,10);
    //     const accountId = selectedInvoice.account_id;
    
    //     // set up object with params for final lambda function and variables for lambda authorizer on API Gateway
    //     const config = {
    //         headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
    //         params: {periodEnd: periodEnd, invoiceId: invoiceId, accountId: accountId}
    //     };

    //     console.log('fetching pdf with params: ', config.params);
        
    //     // call lambda function to get master data
    //     let pdfBase64, fileName;
    //     try {
    //         const res = await axios.get(AWS_API + 'form-submissions/statement-of-entitlement', config);
    //         console.log('data fetch res: ', res.data);
    //         pdfBase64 = res.data.pdfBase64;
    //         fileName = res.data.fileName;

    //     } catch (err) {
    //         setError(handleAwsApiError(err, history, location) ?? 'Error encountered while fetching master data');
    //     }            

    //     // const linkSource = `data:application/pdf;base64,${pdfBase64}`;
    //     // const downloadLink = document.createElement("a");
    
    //     // downloadLink.href = linkSource;
    //     // downloadLink.download = fileName;
    //     // downloadLink.click();        
    //     downloadPDFFromBase64(pdfBase64, fileName);

    //     setLoading(false);


    // }     

    const getPresignedUrl = async (selected, urlType) => {
        
        setLoading(true);

        // exit if more than one document selected
        if (selected.length > 1) {
            alert('Please select only one row');
            setLoading(false);
            return false;
        }        

        // get selected row
        const table = dataMap[showContent]?.data;
        const uniqueSelectId = bootstrapTableConfig[table]?.uniqueSelectId;
        const id = selected[0];
        console.log('id: ', id);
        const selectedRow = data.filter(obj => obj[uniqueSelectId] === id)?.[0];

        // url type config
        let fileKey;
        if (urlType === 'enrolment-document') {
            fileKey = selectedRow?.file_key;
            console.log('fileKey: ', fileKey);
        } else if (urlType === 'statement-of-entitlement') {
            const periodEnd = selectedRow?.period_end?.slice(0,10);
            const accountId = selectedRow?.account_id;
            const fileName = periodEnd + '-SoE-account-' + accountId + '-invoice-' +  id + '.pdf';
            fileKey = 'statements-of-entitlement/' + accountId + '/' + fileName;            
        }
        
        // set up object with params for final lambda function and variables for lambda authorizer on API Gateway
        const config = {
            headers: {authorization: props.token, appclientid: cognitoClientId, userpoolid: cognitoUserpoolId}, 
            params: {urlType: urlType, fileKey: fileKey}
        };

        let url;
        try {
            const res = await axios.get(AWS_API + 'form-submissions/presigned-url', config);
            console.log('data fetch res: ', res.data);
            url = res.data.url;

        } catch (err) {
            setError(handleAwsApiError(err, history, location) ?? 'Error encountered while fetching master data');
        }        
        
        window.open(url,'_newtab');

        setLoading(false);        
    }

    const refreshData = () => {
        
        // reset data and page number and toggle value in state to trigger data reload
        resetDataState();
        setDataRefreshToggle(!dataRefreshToggle);

    }

    
    // construct week commencing < > UI to use in multiple areas
    let weekEnding = new Date(selectedWeekCommencing);
    // const periodLength = (weekCommencingAutoFilterTables[dataMap[showContent]?.data]?.periodLengthDays) ?? 6;
    const periodLength = dataMap[showContent]?.periodLengthDays ?? 6;
    weekEnding.setDate(weekEnding.getDate() + periodLength);
    const weekSelectUI = (
        // <div className={classes.Row}>
        
        <div className={classes.WeekSelect}>
            <button className='btn btn-secondary btn-sm' onClick={() => handleChangeSelectedWeekCommencing(selectedWeekCommencing, setSelectedWeekCommencing, -1)}>◀</button>
            <h6 className={classes.WeekSelectText}><i>{selectedWeekCommencing.toDateString("en-GB", {timeZone: timezone}).slice(0,10)} - {weekEnding.toDateString("en-GB", {timeZone: timezone})}</i></h6>
            <button className='btn btn-secondary btn-sm' onClick={() => handleChangeSelectedWeekCommencing(selectedWeekCommencing, setSelectedWeekCommencing, 1)}>▶</button>       
            <button className='btn btn-secondary btn-sm' onClick={() => handleChangeSelectedWeekCommencing(null, null, null, null, true)}>Today</button>
        </div>       
    );

    

    // add in title content/buttons depending on which view mode we're in
    let contentTitle, contentMessages, contentButtons, extraContent;
    const iconSize = '1rem';
    const iconColor = 'white';
    if (showContent === 'pendingISCaseClaims') {
        contentTitle = 'Upcoming IS Case Claims';
        contentMessages = 
            <Fragment>
                <p><i>In approving the following submissions, you are agreeing to the following statement: "I declare that the Additional Educator/s listed were engaged to increase the staff to child ratio above that required by state or territory licensing and regulatory requirements"</i></p>
               
            </Fragment>;
        contentButtons = 
            <Fragment>
                <button className="btn btn-danger btn-sm" onClick={() => postSubmissionReview(selected,  'hold')}><AiOutlineStop size={iconSize} color={iconColor} /></button>
                <button className="btn btn-success btn-sm" onClick={() => postSubmissionReview(selected,  'approved')}><TiTickOutline size={iconSize} color={iconColor} /></button>
                <button className="btn btn-warning btn-sm" onClick={() => postToAppCentrelinkLambda('run-is-claim-auto-submission', selected)}>Submit Case Claims</button>                
            </Fragment>
    } else if (showContent === 'pendingSessionReports') {

        contentTitle = 'Pre-Submissions Checklist';
        contentMessages = <p><i>All session reports will be submitted 10pm Friday unless they have been given a "hold" status. Any likely causes of submission failure are highlighted in red.</i></p>
        contentButtons = 
            <Fragment>
                <button className="btn btn-warning btn-sm" onClick={() => postToAppCentrelinkLambda('run-step-weekly-session-reports', selected)}>Run Submission Engine</button>
                <button className="btn btn-danger btn-sm" onClick={() => postSubmissionReview(selected, 'hold')}><AiOutlineStop size={iconSize} color={iconColor} /></button>
                <button className="btn btn-success btn-sm" onClick={() => postSubmissionReview(selected, 'approved')}><TiTickOutline size={iconSize} color={iconColor} /></button>
            </Fragment>
    } else if (showContent === 'amendedSessionReports') {

        contentTitle = 'Session Report Amendments';
        contentMessages = null;
        contentButtons = 
            <Fragment>
            </Fragment>
    // } else if (showContent === 'newCreatedSessionReports') {

    //     contentTitle = 'Session Reports Created This Week';
    //     contentMessages = null;
    //     contentButtons = 
    //         <Fragment>
    //         </Fragment>
    } else if (showContent === 'pendingInvoices') {

        contentTitle = 'Pre-Invoices Checklist';
        contentMessages = (awsSecrets) ? <p>
            <b>Invoice Parameters To Use - </b> 
                Status: <i>{awsSecrets?.xero?.invoiceStatus} </i>&nbsp;&nbsp;&nbsp;&nbsp;
                Invoice Account Code: <i>{awsSecrets?.xero?.invoiceAccountCode} </i>&nbsp;&nbsp;&nbsp;&nbsp;
                Due In: <i>{awsSecrets?.xero?.billDueInDays} days
            </i></p> : <SmallSpinnerDark />;
        contentButtons = 
            <Fragment>
                <button className="btn btn-warning btn-sm" onClick={() => postToAppCentrelinkLambda('run-step-create-invoices-for-selection', selected)}>Run Invoice Engine For Selection</button>
                {/* <button className="btn btn-danger btn-sm" onClick={() => postSubmissionReview(selected, 'hold')}><AiOutlineStop size={iconSize} color={iconColor} /></button> */}
                {/* <button className="btn btn-success btn-sm" onClick={() => postSubmissionReview(selected, 'approved')}><TiTickOutline size={iconSize} color={iconColor} /></button> */}
            </Fragment>
    // } else if (showContent === 'lateRollChanges') {

    //     contentTitle = 'Session Report Changes After Submission';
    //     contentMessages = <p><i>The below are an indication of sessions which may have changed since the corresponding session report was submitted, and therefore may need investigating and/or resubmitting.</i></p>
    //     contentButtons = 
    //         <Fragment>
    //         </Fragment>

    } else if (showContent === 'viewIsCases') {
        contentTitle = 'IS Cases';
        contentMessages = null;
        contentButtons = 
            <Fragment>
                <button className="btn CustomButtonPrimary btn-sm" onClick={() => setShowSubmitCaseClaimModal(true)}>Submit New Case Claim</button>
                <button className="btn btn-success btn-sm" onClick={() => postToAppCentrelinkLambda('query-and-write-is-cases')}>Update IS Cases</button>
            </Fragment>        
    } else if (showContent === 'submittedISCaseClaims') {
        contentTitle = 'Submitted IS Case Claims';
        contentMessages = <p>
            <i className="text text-warning">Note: Re-running automated submission process will run the auto-claim process for all selected IS-case and week-ending combinations, inclulding both face-to-face and non face-to-face claims where applicable</i>
            <br/>
            <i className="text text-info">Note: Validation will be attempted for selected Case Claim IDs only</i>
        </p>
        contentButtons = 
            <Fragment>
                <button className="btn btn-warning btn-sm" onClick={() => postToAppCentrelinkLambda('resubmit-is-claims', selected)}>Re-Run Automated Case Claim Submission</button>
                <button className="btn CustomButtonPrimary btn-sm" onClick={() => postToAppCentrelinkLambda('validate-is-claims', selected)}>Validate Case Claims</button>
                <button className="btn btn-danger btn-sm" onClick={() => postToAppCentrelinkLambda('cancel-is-claims', selected)}>Cancel Case Claims</button>
            </Fragment>           
    } else if (showContent === 'submittedSessionReports') {
        contentTitle = 'Session Reports';
        contentMessages = <p>
            <i className="text text-warning">Note: Resubmitting or updating will submit only selected session reports</i>
            <br/>
            <i className="text text-info">Note: Validation will be attempted for all session reports between the first period-start and the last period-end selected</i>
        </p>
        contentButtons = 
        <Fragment>
                {/* <button className="btn btn-warning btn-sm" onClick={() => postToAppCentrelinkLambda('trigger-submit-session-reports', selected)}>Run Submissions For Week</button> */}
                <button className="btn CustomButtonPrimary btn-sm" onClick={() => handlePrompt('resubmit selected session reports', () => postToAppCentrelinkLambda('submit-specified-session-reports', selected, 'INIT'), setPrompt)}>Resubmit Individual SRs</button>
                <button className="btn CustomButtonPrimary btn-sm" onClick={() => handlePrompt('validate all session reports', () => postToAppCentrelinkLambda('validate-session-reports', selected), setPrompt)}>Validate SRs</button>
                <button className="btn CustomButtonPrimary btn-sm" onClick={() => handlePrompt('update selected session reports', () => postToAppCentrelinkLambda('submit-specified-session-reports', selected, 'VARY'), setPrompt)}>Update SRs</button>
                <button className="btn CustomButtonPrimary btn-sm" onClick={() => handlePrompt('withdraw selected session reports', () => handleWithdrawSessionReport(selected), setPrompt)}>Withdraw SRs</button>
                {/* adding back in ** after adding session report amendment automation, removing ability to manually recreate SRs */}
                <button className="btn CustomButtonPrimary btn-sm" onClick={() => handlePrompt('delete and recreate session report data', () => postToAppCentrelinkLambda('recreate-billing-data-for-children-centre', selected), setPrompt)}>Recreate SR</button>  
                {/* <button className="btn CustomButtonPrimary btn-sm" onClick={() => setShowReturnFeeModal(true)}>Create Return Fee Reduction</button>   */}
                {/* <p> */}
                <button className="btn btn-danger btn-sm" onClick={() => postSubmissionReview(selected, 'hold')}><AiOutlineStop size={iconSize} color={iconColor} /></button>
                <button className="btn btn-success btn-sm" onClick={() => postSubmissionReview(selected, 'approved')}><TiTickOutline size={iconSize} color={iconColor} /></button>
                {/* additional params component used in Centrelink testing to send extra parameters with requests, in future should build into more useable UI if needed in production */}
                {showAdditionalParams ? <button className='btn btn-secondary btn-sm' onClick={() => setShowAdditionalParams(false)}>Hide Parameters</button> : <button className='btn btn-secondary btn-sm' onClick={() => setShowAdditionalParams(true)}><FiSettings size={iconSize} color={iconColor} /></button>}

                {/* </p> */}
            </Fragment>  
        extraContent = showAdditionalParams ? <> <AdditionalParams setParams={setAdditionalParams} /> <br/></> : null;

    } else if (showContent === 'centrelinkPayments') {
        contentTitle = 'Session Report Payments';
        contentMessages = null;
        contentButtons = null;
        
    } else if (showContent === 'openInvoicesCheck') {
        contentTitle = 'Open Invoices Check';
        contentMessages = null;
        contentButtons = null;
        
    } else if (showContent === 'generalActionsRequired') {
        contentTitle = 'Actions Required';
        contentMessages = null;
        contentButtons = null;
        
    } else if (showContent === 'invoices') {
        contentTitle = 'Invoices';
        contentMessages = null;
        contentButtons = <Fragment>
            <button className="btn btn-success btn-sm" onClick={() => getPresignedUrl(selected, 'statement-of-entitlement')}>Download Statement</button>
            <button className="btn CustomButtonPrimary btn-sm" onClick={() => postToAppCentrelinkLambda('retrieve-invoice-details', selected)}>Retrieve Payment Details</button>
            <button className="btn btn-warning btn-sm" onClick={() => handlePrompt(`email statements to ${selected?.length} accounts`, () => postToAppCentrelinkLambda('run-step-send-invoice-emails', selected), setPrompt)}>Send Invoice Email</button>
            {/* <button className="btn btn-success btn-sm" onClick={() => arrayObjsToCsv(data?.filter(obj => selected?.includes(obj[bootstrapTableConfig[dataMap[showContent]?.data]?.uniqueSelectId])))}>Export CSV</button> */}
        
        
        </Fragment>
    } 
    // else if (showContent === 'documents') {
    //     contentTitle = 'Documents';
    //     contentMessages = null;
    //     contentButtons = <button className="btn btn-success btn-sm" onClick={() => getPresignedUrl(selected, 'enrolment-document')}>Download Document</button>
    // }

    const dynamicClassFunction = (cell, row, rowIndex, colIndex) => {
        // console.log('rowIndex: ', rowIndex);
        // console.log('colIndex: ', colIndex);
        
        // get table and column of cell in question
        const table = dataMap[showContent]?.data;
        const keys = Object.keys(row).filter(key => !bootstrapTableConfig[table]?.excludeColumns?.includes(key));
        const col = keys[colIndex];
        
        const className = handleBSTableColourCoding(table, row, col, cell);
        return className;
    }


    // add default filter for selected tables
    let parentFilter;
    // if (showContent === 'pendingSessionReports' && selectedWeekCommencing) {
    // if (selectedWeekCommencing && !loading && Object.keys(weekCommencingAutoFilterTables).includes(dataMap[showContent]?.data)) {
    if (selectedWeekCommencing && !loading && dataMap[showContent]?.autoFilter) {
        console.log('[Centrelink.js] - adding week_commencing parent filter with value: ',  selectedWeekCommencing.toLocaleDateString("sv-SW", {timeZone: timezone}))

        // attempt to add filter with slight delay to ensure table component has mounted, if successful can try move logic into BSTable 
        // setTimeout(() => {
            // const column = weekCommencingAutoFilterTables[dataMap[showContent]?.data]?.column;
            const column = dataMap[showContent]?.autoFilter?.column;
            parentFilter = {column: column, value: selectedWeekCommencing.toLocaleDateString("sv-SW", {timeZone: timezone})};

        // }, 100);
    }

    // data transforms
    let transformedData = [];
    if (data?.length > 0) {
        
        // create deep clone of object to put into form submission without changing original object if this form is loaded
        transformedData =  cloneDeep(data);

        // add in optional extra display columns if needed
        if (showContent === 'pendingInvoices') {
            transformedData.forEach(obj => obj.direct_debit = <DirectDebitStatus email={obj.account_email} directDebitData={directDebitData} alternativeBillingArrangementData={alternativeBillingArrangementData}/>)
        }
    }
    // console.log('transformedData: ', transformedData);
    
    let tableContent;
    if (!loading) {
        tableContent = (
            <Fragment>
                {data 
                    ? <div >
                        <BSTable 
                            parentFilter={parentFilter} 
                            dynamicClassFunction={dynamicClassFunction} 
                            selected={selected} 
                            setSelected={setSelected} 
                            table={dataMap[showContent]?.data} 
                            detailTable={dataMap[showContent]?.detailData} 
                            data={transformedData} 
                            getDetailData={getDetailData}
                            detailData={detailData} expanded={expanded} setExpanded={setExpanded}
                            clearDetailData={clearDetailData}
                        /> 

                    </div>
                : null}              
            </Fragment>
        );
    }

    let content = (
        <Fragment>
            {/* {Object.keys(weekCommencingAutoFilterTables).includes(dataMap[showContent]?.data) && weekSelectUI} */}
            {dataMap[showContent]?.filterBy && weekSelectUI}
            <h3>{contentTitle}</h3>
            <hr />
            {contentMessages}
            <div className={classes.HeaderButtonRow} >
                <button className='btn btn-sm btn-secondary' onClick={refreshData}><IoRefreshOutline size={iconSize} color={iconColor} /></button>
                {contentButtons}
            </div>  
            {extraContent}
            {tableContent}              
        </Fragment>              
    );

    // render new component if requested
    if (!showContent && showComponent) {
        if (showComponent === 'isInnovativeCases') {
            content = <ISInnovativeCases token={props.token} centres={centres}/>
        } else if (showComponent === 'isInnovativeCaseClaims') {
            content = <ISInnovativeCaseClaims token={props.token} centres={centres}/>
        } else if (showComponent === 'isMessages') {
            content = <ISMessages token={props.token} centres={centres}/>
        } else if (showComponent === 'isPayments') {
            content = <ISPayments token={props.token} centres={centres}/>
        }
    }
    
    // let returnFeeModal;
    // if (showReturnFeeModal) {
        //     returnFeeModal = (
    //         <Modal show={showReturnFeeModal} modalClosed={() => setShowReturnFeeModal(false)}>
    //             <h3>Oops, something went wrong!</h3>
    //             <hr/>
    //             <p>{error}</p>
    //         </Modal>            
    //     );
    // }
    
    
    
    return (
        <Fragment >
            <Modal show={error} modalClosed={() => setError(false)}>
                <h3>Oops, something went wrong!</h3>
                <hr/>
                <p>{error}</p>
            </Modal>
            <Banner show={bannerText}>{bannerText}</Banner>   
            <Prompt data={prompt} setData={setPrompt} />
            {(loading) && <BackdropSpinner spinner='small-light' />}     
            <Modal show={showReturnFeeModal} modalClosed={() => setShowReturnFeeModal(false)}>
                <h3>Create a Return Fee Reduction</h3>
                <hr/>
                <ParamsForm 
                    data={returnFeePostData} 
                    setData={setReturnFeePostData} 
                    config={[
                        {name: 'childName', type: 'text', value: data?.filter(obj => selected.includes(obj.id))[0]?.child}, 
                        {name: 'enrolmentID', type: 'text', value: data?.filter(obj => selected.includes(obj.id))[0]?.ccs_enrolment_id}, 
                        {name: 'sessionReportStartDate', type: 'text', value: data?.filter(obj => selected.includes(obj.id))[0]?.week_commencing}, 
                        {name: 'totalFeeReductionAmountForWeek', type: 'text', value: +data?.filter(obj => selected.includes(obj.id))[0]?.ccs + +data?.filter(obj => selected.includes(obj.id))[0]?.accs}, 
                        {name: 'amountPassedOnToIndividual', type: 'number'},
                        {name: 'amountNotPassedOnToIndividual', type: 'number'},
                        {name: 'returnFeeReductionReason', type: 'text'},
                    ]}
                    />
                <button className="btn btn-success btn-block" onClick={() => postCCSGenericWrite(returnFeePostData, null, 'create-return-fee-reduction')}>Submit</button>
            </Modal>                                

            <Modal show={showSubmitCaseClaimModal} modalClosed={() => setShowSubmitCaseClaimModal(false)}>
                <h3>Submit a New IS Case Claim</h3>
                <hr/>
                <ParamsForm 
                    data={submitCaseClaimData} 
                    setData={setSubmitCaseClaimData} 
                    config={[
                        {name: 'isCaseId', type: 'text', value: selected[0]}, 
                        {name: 'periodStart', type: 'date'}, 
                        {name: 'periodEnd', type: 'date'}, 
                        {name: 'mondayHoursCap', type: 'number'},
                        {name: 'tuesdayHoursCap', type: 'number'},
                        {name: 'wednesdayHoursCap', type: 'number'},
                        {name: 'thursdayHoursCap', type: 'number'},
                        {name: 'fridayHoursCap', type: 'number'},
                    ]}
                    />
                    <p><i>In submitting this case claim, you are agreeing to the following statement: "I declare that the Additional Educator/s listed were engaged to increase the staff to child ratio above that required by state or territory licensing and regulatory requirements"</i></p>
                <button className="btn btn-success btn-block" onClick={() => handleSubmitCaseClaim(submitCaseClaimData)}>Submit</button>
            </Modal>                                

            {/* <div className={'LeftBar'}>
                <button className={['btn', 'btn-block', 'CustomButtonPrimary'].join(' ')} onClick={() => handleDisplayContent('pendingSessionReports')}>Upcoming Session Reports</button>
                <button className={['btn', 'btn-block', 'CustomButtonPrimary'].join(' ')} onClick={() => handleDisplayContent('submittedSessionReports')}>Submitted Session Reports</button>
                <button className={['btn', 'btn-block', 'CustomButtonPrimary'].join(' ')} onClick={() => handleDisplayContent('centrelinkPayments')}>Centrelink Payments</button>
                <button className={['btn', 'btn-block', 'CustomButtonPrimary'].join(' ')} onClick={() => handleDisplayContent('invoices')}>Invoices</button>
                <button className={['btn', 'btn-block', 'CustomButtonPrimary'].join(' ')} onClick={() => handleDisplayContent('documents')}>Documents</button>
                <button className={['btn', 'btn-block', 'CustomButtonPrimary'].join(' ')} onClick={() => setShowInclusionSupportOptions(!showInclusionSupportOptions)}>▼ Inclusion Options</button>


                {showInclusionSupportOptions &&
                <Fragment>

                    <button className='btn btn-block btn-secondary btn-sm' onClick={() => handleDisplayContent('viewIsCases')}>IS Cases</button>
                    <button className='btn btn-block btn-secondary btn-sm' onClick={() => handleDisplayContent('pendingISCaseClaims')}>Upcoming Case Claims</button>
                    <button className='btn btn-block btn-secondary btn-sm' onClick={() => handleDisplayContent('submittedISCaseClaims')}>Submitted Case Claims</button>
                    <button className='btn btn-block btn-secondary btn-sm' onClick={() => handleDisplayComponent('isInnovativeCases')}>IS Innovative Solutions Cases</button>
                    <button className='btn btn-block btn-secondary btn-sm' onClick={() => handleDisplayComponent('isInnovativeCaseClaims')}>IS Innov. Solutions Claims</button>
                    <button className='btn btn-block btn-secondary btn-sm' onClick={() => handleDisplayComponent('isMessages')}>IS Messages</button>
                    <button className='btn btn-block btn-secondary btn-sm' onClick={() => handleDisplayComponent('isPayments')}>IS Payments</button>
                </Fragment>
                
                }
            </div> */}

                {/* main content */}
            <div className={classes.CentreLink}>
                {content}
                {/* infinite scroll detection */}
                <div ref={ref}>
                    {tableLoading && <SmallSpinnerDark />}
                </div>
            </div>

        </Fragment>
    );
}

export default CentreLink;