export const masterDataPrimaryKeys = {
    groups: ['group_id'],
    guardians: ['guardian_id'],
    all_contacts: ['person_id'],
    all_user_accounts: ['person_id'],
    children: ['child_id'],
    accounts: ['account_id'],
    centres: ['centre_id'],
    centre_capacities: ['centre_id', 'session'],
    child_guardian_relationships: ['person_type', 'person_id', 'child_id'],
    child_guardian_authorisations: ['person_type', 'person_id', 'child_id', 'authorisation'],
    children_medical: ['child_id'],
    child_enrolments: ['id'],
    emergency_contacts: ['contact_id'],
    documents_metadata: ['document_id'],
    form_approvals: ['form_id'],
    form_submissions: ['form_id'],
    casual_bookings: ['booking_id'],
    permanent_bookings: ['child_id', 'centre_id', 'session', 'start_date'],
    variables: ['group_id', 'variable'],
    roll: ['id'],
    idp_group_access: ['group_id', 'idp', 'idp_group', 'api_route_allowed', 'api_method_allowed'],
    role_access: ['group_id', 'role', 'entity', 'field', 'value', 'operator'],
    excursions: ['group_id', 'centre_id', 'session', 'start_date', 'end_date'],
    excursion_detail: ['excursion_id', 'key'],
    billing_headers: ['group_id', 'invoice_id'],
    billing_items: ['group_id', 'invoice_id', 'item_id'],

};

// export const accountOwnerDataConfig = {
//     guardians: {
//         visibleFields: ['first_name', 'surname', 'gender', 'email', 'preferred_contact', 'additional_contact', 'dob', 'crn', 'nationality', 'language_spoken_at_home'],
//         editableFields: ['first_name', 'surname', 'gender', ]
//     }
// }

export const masterDataSortKeys = {
    roll: 'date',
    casual_bookings: 'date',
}

// export const updateTableByFormType = {
//     updateBookingSchedule: {
//         table: 'permanent_bookings',
//         updateCols: ['mon_1', 'tue_1', 'wed_1', 'thu_1', 'fri_1', 'mon_2', 'tue_2', 'wed_2', 'thu_2', 'fri_2'],
//         updateValueName: {
//             fortnightly: ['mon1', 'tue1', 'wed1', 'thu1', 'fri1', 'mon2', 'tue2', 'wed2', 'thu2', 'fri2'], 
//             weekly: ['mon1', 'tue1', 'wed1', 'thu1', 'fri1', 'mon1', 'tue1', 'wed1', 'thu1', 'fri1']
//         }
//     }
// }
// export const formioTypeToFormMap = {
//     'bulkEnrol': 'enrol'
// }
export const sessionTypes = [ 'bsc','asc', 'vac'];

export const childFlagTypes = ['at_risk', 'other'];

export const feedbackQuestionProcesses = [
    {
        value: 'Kiosk',
        label: 'Kiosk'
    },
    {
        value: 'End Permenant Booking Schedule',
        label: 'End Permenant Booking Schedule'
    }
];

export const endPermScheduleFeedbackOptions = [
    {
        value: "Change of work location",
        label: "Change of work location"
    },
    {
        value: "Change of schedule",
        label: "Change of schedule"
    },
    {
        value: "Child no longer wishes to attend",
        label: "Child no longer wishes to attend"
    },
    {
        value: "Other",
        label: "Other"
    },
];

export const formioConfig = {
    enrol: {
        useForm: 'enrol',
        postToRoute: 'initial-master-data/write'
    },
    // enrolNewChild: {
    //     useForm: 'enrolNewChild',
    //     postToRoute: 'initial-master-data/write'
    // },
    adminEnrol: {
        useForm: 'enrol',
        postToRoute: 'initial-master-data/write'
    },
    bulkEnrol: {
        useForm: 'enrol',
        postToRoute: 'initial-master-data/write'
    },
    updateBookingSchedule: {
        updateConfig: {
            table: 'permanent_bookings',
            updateCols: ['mon_1', 'tue_1', 'wed_1', 'thu_1', 'fri_1', 'mon_2', 'tue_2', 'wed_2', 'thu_2', 'fri_2'],
            updateValueName: {
                fortnightly: ['mon1', 'tue1', 'wed1', 'thu1', 'fri1', 'mon2', 'tue2', 'wed2', 'thu2', 'fri2'], 
                weekly: ['mon1', 'tue1', 'wed1', 'thu1', 'fri1', 'mon1', 'tue1', 'wed1', 'thu1', 'fri1']
            }
        }
    },
    incidentRecord: {},
    permissionSlip: {},
    enrolmentFeedback: {},
}
export const autoApprovedForms = ['permissionSlip', 'enrolmentFeedback', 'adminEnrol', 'bulkEnrol'];

export const bootstrapTableDateFields = ['last_attendance_date', 'scheduled_date', 'form_for_date', 'dob', 'session_report_start_date', 'date','created_on', 'clearing_date', 'week_commencing', 'week_ending', 'start_date', 'end_date', 'period_start', 'period_end', 'invoice_created_at', 'medicare_expiry_date', 'status_changed_at'];
export const bootstrapTableTimeFields = ['checked_in1', 'checked_out1', 'checked_in2', 'checked_out2', 'time_in', 'time_out'];
export const bootstrapTableDateTimeFields = ['status_last_updated', 'statusTimestamp', 'centrelink_data_updated', 'balances_updated_at', 'statement_created', 'statement_attached', 'soe_created_at',  'created_at',  'soe_attached_at','completed_at', 'submitted_at', 'validated_at', 'last_updated', 'updated_at', 'approved_at', 'account_created_at', 'changed_date', 'session_report_submitted', 'session_report_created', 'updated_approval_at', 'status_changed_at_time'];
export const bootstrapNumberFields = ['outstanding_balance']

export const bootstrapTableConfig = {
    pending_is_case_claim_submissions: {
        excludeColumns: ['centre_id', 'week_ending'],
        uniqueSelectId: 'row_number',
        detailLinkingId: 'case_week',
        filterColumns: [{columns: ['centre_name', 'week_commencing'], filterType: 'select'}]
    },
    pending_is_case_claim_submissions_detail: {
        uniqueSelectId: 'id',
    },
    pending_session_reports: {
        excludeColumns: ['id', 'group_id', 'centre_id', 'week_ending', 'child_centre_week'],
        detailLinkingId: 'child_centre_week',
        uniqueSelectId: 'id',
        filterColumns: [{columns: ['centre_name', 'week_commencing', 'header_status', 'warnings'], filterType: 'select'}, {columns: ['child'], filterType: 'text'}]
    },
    view_pending_session_reports_time_limited: {
        excludeColumns: ['id', 'group_id', 'centre_id', 'week_ending', 'child_centre_week'],
        detailLinkingId: 'child_centre_week',
        uniqueSelectId: 'id',
        filterColumns: [{columns: ['centre_name', 'week_commencing', 'header_status', 'warnings'], filterType: 'select'}, {columns: ['child'], filterType: 'text'}]
    },
    view_session_report_check: {
        uniqueSelectId: 'id',
    },
    view_pending_session_reports_detail: {
        uniqueSelectId: 'id',
        excludeColumns: ['week_ending', 'week_commencing', 'group_id', 'centre_id' ]
    },
    view_latest_entitlements: {
        uniqueSelectId: 'id',
        excludeColumns: ['id', 'group_id', 'child_id'],
        filterColumns: [{columns: ['child'], filterType: 'text'}, {columns: ['rank'], filterType: 'select'}],
    },
    view_submitted_session_reports: {
        excludeColumns:  ['id', 'centre_id', 'child_id', 'week_ending', 'group_id', 'service_crn'],
        filterColumns: [{columns: ['week_commencing', 'submit_status', 'centre'], filterType: 'select'}, {columns: ['child', 'validated_at', 'guardian'], filterType: 'text'}],
        detailLinkingId: 'id',
        uniqueSelectId: 'id'
    },
    view_submitted_session_reports_v2: {
        excludeColumns:  ['id', 'id_version',  'centre_id', 'child_id', 'week_ending', 'group_id', 'service_crn', 'created_at_str'],
        filterColumns: [{columns: ['week_commencing', 'submit_status', 'centre', 'original_invoice', 'amended_invoice', 'version', 'validated_status'], filterType: 'select'}, {columns: ['child', 'validated_at', 'guardian'], filterType: 'text'}],
        detailLinkingId: 'id_version',
        uniqueSelectId: 'id'
    },
    view_late_created_session_reports: {
        excludeColumns:  ['id', 'id_version',  'centre_id', 'child_id', 'week_ending', 'group_id', 'service_crn', 'created_at_str'],
        filterColumns: [{columns: ['version', 'week_commencing', 'submit_status', 'centre', 'original_invoice', 'amended_invoice'], filterType: 'select'}, {columns: ['child', 'validated_at', 'guardian'], filterType: 'text'}],
        detailLinkingId: 'id_version',
        uniqueSelectId: 'id'
    },
    view_amended_session_reports: {
        excludeColumns:  ['id', 'id_version', 'centre_id', 'child_id', 'week_ending', 'group_id', 'service_crn', 'gap_delta'],
        filterColumns: [{columns: ['week_commencing', 'submit_status', 'centre'], filterType: 'select'}, {columns: ['child', 'validated_at', 'guardian'], filterType: 'text'}],
        detailLinkingId: 'id_version',
        uniqueSelectId: 'id_version'
    },
    view_session_report_payments: {
        excludeColumns:  ['group_id', 'centre_id', 'child_id', 'billing_header_id', 'fee_flag', 'subsidy_flag', 'paid_flag'],
        filterColumns: [{columns: ['period_start', 'centre'], filterType: 'select'}, {columns: ['child_name'], filterType: 'text'}],
        detailLinkingId: 'billing_header_id',
        uniqueSelectId: 'billing_header_id'
    },
    view_roll_changes_after_submission: {
        excludeColumns:  ['id', 'centre_id', 'child_id', 'group_id', 'roll_date_saturday', 'roll_id', 'roll_date_week_start'],
        filterColumns: [{columns: ['centre', 'week_commencing'], filterType: 'select'}, {columns: ['child_name'], filterType: 'text'}],
        uniqueSelectId: 'id'
    },
    view_submitted_session_reports_detail: {
        uniqueSelectId: 'row_number',
        excludeColumns: ['row_number']
    },
    general_actions_required_log: {
        primaryKeys: ['id'],
        uniqueSelectId: 'id',
        filterColumns: [{columns: ['status', 'action_type'], filterType: 'select'}],
        editableFields: [{columns: ['status'], dataType: 'SELECT', options: [{value: 'pending', label: 'Pending'}, {value: 'actioned', label: 'Actioned'}]}],
    },
    view_general_communications_log: {
        primaryKeys: ['id'],
        excludeColumns: ['id', 'centre_id', 'group_id', 'guardian_id', 'child_id'],
        uniqueSelectId: 'id',
        filterColumns: [{columns: ['status', 'communication_type', 'centre_name'], filterType: 'select'}, {columns: ['email', 'first_name', 'surname', 'communication_name' ], filterType: 'text'}],
    },
    view_general_actions_required: {
        uniqueSelectId: 'id',
        filterColumns: [{columns: ['status', 'action_type'], filterType: 'select'}, {columns: ['child_name'], filterType: 'text'}],
        excludeColumns: ['child_id'],
        defaultFilters: [{columns: ['status'], value: 'pending'}],
        // editableFields: [{columns: ['status'], dataType: 'SELECT', options: [{value: 'pending', label: 'Pending'}, {value: 'actioned', label: 'Actioned'}]}],gi
    },
    view_submitted_session_reports_detail_v2: {
        uniqueSelectId: 'row_number',
        excludeColumns: ['row_number', 'id_version']

    },
    view_amended_session_reports_detail: {
        uniqueSelectId: 'row_number',
        excludeColumns: ['row_number']

    },
    view_forms_needing_approval: {
        uniqueSelectId: 'form_id',
        excludeColumns: ['group_id', 'centre_id', 'account_id', 'form_for_date', 'ref_type'],
        filterColumns: [{columns: ['centre'], filterType: 'select'}, {columns: ['submitted_by'], filterType: 'text'}],
        editableFields: [{columns: ['tag', 'notes'], dataType: 'TEXT'}],
        primaryKeys: ['form_id'],
        sourceTable: 'form_submissions'
    },
    view_submitted_is_claims: {
        excludeColumns:  ['service_crn', 'week_ending'],
        filterColumns: [{columns: ['is_case_id', 'is_case_claim_id', 'week_commencing'], filterType: 'select'}],
        uniqueSelectId: 'id'
    },
    is_cases: {
        uniqueSelectId: 'is_case_id',
        detailLinkingId: 'is_case_id'
    },
    view_is_cases: {
        uniqueSelectId: 'is_case_id',
        detailLinkingId: 'is_case_id'
    },
    is_case_children: {
        uniqueSelectId: 'is_case_id'
    },
    invoices: {
        uniqueSelectId: 'invoice_id',
        detailLinkingId: 'invoice_id'
    },
    invoice_items: {
        uniqueSelectId: 'invoice_id'
    },
    cl_payments: {
        uniqueSelectId: 'payment_id',
        detailLinkingId: 'payment_id',
        filterColumns: [{columns: ['clearing_date'], filterType: 'select'}],
        defaultSorted : [{dataField: 'clearing_date', order: 'desc'}]
    },
    cl_payment_items: {
        uniqueSelectId: 'id'
    },
    view_pending_invoices: {
        uniqueSelectId: 'id',
        detailLinkingId: 'id',
        excludeColumns: ['statement_attached', 'group_id', 'id', 'centrelink_fortnight', 'centre_ids', 'centre_id', 'errors', 'period_end', 'fee', 'subsidy', 'no_session_reports_flag', 'warning_flag'],
        filterColumns: [{columns: ['period_start', 'centres',  'status'], filterType: 'select'}, {columns: ['centre_enrolments'], filterType: 'multi-select'}, {columns: ['account_email', 'account_owner'], filterType: 'text'}],
        // defaultSorted : [{dataField: 'period_start', order: 'desc'}]
    },
    view_pending_invoices_detail: {
        uniqueSelectId: 'id',
        excludeColumns: ['account_id', 'id']
    },
    account_payments: {
        uniqueSelectId: 'id',
        filterColumns: [{columns: ['provider', 'status'], filterType: 'select'}],
        defaultSorted : [{dataField: 'created_at', order: 'desc'}],
    },
    view_invoices: {
        uniqueSelectId: 'invoice_id',
        detailLinkingId: 'invoice_id',
        filterColumns: [{columns: ['period_start', 'status', 'centres', 'email_status'], filterType: 'select'}, {columns: ['surname', 'email', 'preferred_contact', 'name'], filterType: 'text'}],
        defaultSorted : [{dataField: 'created_at', order: 'desc'}],
        excludeColumns: ['statement_attached', 'preferred_contact', 'email', 'account_id', 'group_id', 'ext_invoice_id', 'period_end', 'invoice_id']
    },
    view_open_invoices: {
        uniqueSelectId: 'invoice_id',
        detailLinkingId: 'invoice_id',
        filterColumns: [{columns: ['period_start', 'status', 'centres'], filterType: 'select'}, {columns: ['surname', 'email', 'preferred_contact', 'name'], filterType: 'text'}],
        defaultSorted : [{dataField: 'created_at', order: 'desc'}],
        excludeColumns: ['preferred_contact', 'email', 'account_id', 'group_id', 'ext_invoice_id', 'period_end', 'invoice_id']
    },
    view_accounts_multiple_open_invoices: {
        uniqueSelectId: 'account_id',
        detailLinkingId: 'account_id',
        filterColumns: [{columns: ['name', 'email', 'account_id'], filterType: 'text'}],
        excludeColumns: ['group_id']
    },
    view_documents_metadata: {
        uniqueSelectId: 'document_id',
        excludeColumns: ['group_id', 'file_key'],
        filterColumns: [{columns: ['file_type'], filterType: 'select'}, {columns: ['child_surname', 'guardian_surname', 'guardian_email'], filterType: 'text'}],
    },
    view_account_activity_monitoring: {
        uniqueSelectId: 'account_id+centre',
        excludeColumns: ['account_id+centre', 'userpool_id', 'group_id'],
        filterColumns: [{columns: ['registered', 'centre', 'children', 'casual_bookings', 'booking_schedules_per_child'], filterType: 'select'}, {columns: ['email', 'account_first_name', 'account_surname'], filterType: 'text'}],
    },
    'read-return-fee-reduction': {
        excludeColumns:  ['amountPassedOnToIndividual', 'returnFeeReductionCancelReason', 'returnFeeReductionID', 'updatedSince'],
        uniqueSelectId: 'returnFeeReductionID'
    },
    'read-messages-notifications': {
        excludeColumns:  ['ErrorCodes', '__metadata', 'date', 'updatedSince'],
        uniqueSelectId: 'dateTime'
    },
    'read-messages-correspondences': {
        excludeColumns:  ['ErrorCodes', '__metadata', 'date', 'updatedSince'],
        uniqueSelectId: 'dateTime'
    },
    'read-debt': {
        excludeColumns:  ['ErrorCodes', '__metadata', 'date', 'updatedSince'],
        uniqueSelectId: 'ID'
    },
    'read-debt-alternative-arrangement': {
        excludeColumns:  ['ErrorCodes', '__metadata', 'date', 'updatedSince'],
        uniqueSelectId: 'alternativePaymentArrangementID'
    },
    'read-event-management': {
        excludeColumns:  ['Services', 'SpecialAbsences', 'SpecialConditions', 'includeChildEvents'],
        uniqueSelectId: 'eventID'
    },
    'calculated-incomplete-account-data-view': {
        excludeColumns: [],
        uniqueSelectId: 'account_id',
        filterColumns: [{columns: ['account_name', 'children', 'account_email', 'account_id'], filterType: 'text'}],
    },
    'calculated-admin-roll-view': {
        excludeColumns: ['childId'],
        uniqueSelectId: 'childId',
        // editableFields: [{columns: ['Mon*', 'Tue*', 'Wed*', 'Thu*', 'Fri*'], dataType: 'SELECT', options: [
        //     {value: 'mark-absent', label: 'Cancel/Mark as Absent'},
        //     {value: 'edit-in-out-times', label: 'Edit Sign In/Out Times'},
        //     {value: 'add-session', label: 'Add Session'},
        //     {value: 'remove', label: 'Remove Session'},
        // ]}],
        filterColumns: [{columns: ['name'], filterType: 'text'}],
    },
    'query-payments-by-service': {
        uniqueSelectId: 'payment_id'
    },
    'read-enrolments': {
        excludeColumns:  ['enrolmentID+noticeType+arrangementStartDate', 'createdDateTime', 'lastUpdatedDateTime', 'EnrolmentAdvances', 'Sessions', 'Statuses', 'includeHistory', '__metadata', 'dateOfEvent', 'individualCRN', 'individualDateOfBirth', 'individualFirstName', 'individualLastName', 'isChildInStateCare', 'isRecordOverwritten', 'lateSubmissionReason', 'numberOfWeeksCycle', 'occurrenceNumber', 'reasonCode', 'reasonForPEA', 'recordEffectiveEndDate', 'recordEffectiveStartDate', 'sessionIndicator', 'signingPartyIndividualFirstName', 'signingPartyIndividualLastName', 'signingPartyOrganisationName', 'updatedSince', 'usualEducatorAddressLine1', 'usualEducatorAddressLine2', 'usualEducatorAddressPostCode', 'usualEducatorAddressState', 'usualEducatorAddressSuburb', 'usualEducatorPersonID'],
        uniqueSelectId: 'enrolmentID+noticeType+arrangementStartDate',
        filterColumns: [{columns: ['childFirstName', 'childLastName', 'enrolmentID', 'childCRN'], filterType: 'text'}, {columns: ['arrangementType', 'serviceID', 'enrolmentStatus', 'status'], filterType: 'select'}],
    },
    'read-entitlements': {
        excludeColumns:  ['enrolmentID+dateOfEntitlement', '__metadata'],
        uniqueSelectId: 'enrolmentID+dateOfEntitlement',
    },
    children: {
        uniqueSelectId: 'child_id',
        excludeColumns: ['group_id'],
        filterColumns: [{columns: ['first_name', 'surname'], filterType: 'text'}],
        defaultSorted : [{dataField: 'surname', order: 'asc'}],
    },
    view_children: {
        uniqueSelectId: 'child_id',
        excludeColumns: ['group_id', 'account_id'],
        filterColumns: [{columns: ['first_name', 'surname'], filterType: 'text'}],
        defaultSorted : [{dataField: 'surname', order: 'asc'}],
    },
    addresses: {
        uniqueSelectId: 'address_id',
        excludeColumns: ['group_id'],
        filterColumns: [{columns: ['person_id', 'child_id'], filterType: 'select'}]
    },
    guardians: {
        uniqueSelectId: 'guardian_id',
        excludeColumns: ['group_id'],
        filterColumns: [{columns: ['first_name', 'surname', 'email'], filterType: 'text'}],
        primaryKeys: ['group_id', 'guardian_id']
    },
    groups: {
        uniqueSelectId: 'group_id',
        // editableFields: [{columns: ['group_name', 'provider_crn', 'default_auth'], dataType: 'TEXT'}],
    },
    accounts: {
        uniqueSelectId: 'account_id',
        excludeColumns: ['group_id'],
        filterColumns: [{columns: ['account_owner'], filterType: 'text'}]
    },
    view_accounts: {
        uniqueSelectId: 'account_id+centre_id',
        excludeColumns: ['group_id'],
        filterColumns: [{columns: ['primary_email', 'primary_name', ], filterType: 'text'}, {columns: ['centre_id'], filterType: 'select'}]
    },
    account_refs: {
        uniqueSelectId: 'account_id+ref_type',
        excludeColumns: ['account_id+ref_type', 'group_id'],
    },
    all_user_accounts: {
        uniqueSelectId: 'person_id',
        excludeColumns: ['group_id'],
        filterColumns: [{columns: ['email', 'username', 'userpool_id'], filterType: 'text'}, {columns: ['account_type'], filterType: 'select'}]
    },
    child_flags: {
        uniqueSelectId: 'id',
        excludeColumns: ['group_id'],
        insertRow: [
            {columns: ['child_id'], dataType: 'react-select', options: {source: 'database', name: 'children', value: 'child_id', labels: ['child_id', 'first_name', 'surname']}, mandatory: true},
            {columns: ['flag_type'], dataType: 'react-select', options: {source: 'globalObjectsArray', array: childFlagTypes}, mandatory: true},
            {columns: ['status'], dataType: 'react-select', options: {source: 'hardcoded', list: [{value: 'active', label: 'Active'}, {value: 'ceased', label: 'Ceased'}]}, mandatory: true},
            {columns: ['comment'], dataType: 'text'}
        ]
        // filterColumns: [{columns: ['first_name', 'surname', 'email'], filterType: 'text'}]
    },
    child_approvals: {
        uniqueSelectId: 'id',
        detailLinkingId: 'child_id',
        filterColumns: [{columns: ['child_id'], filterType: 'text'}, {columns: ['approval_status', 'approval_type'], filterType: 'select'}],
        defaultSorted : [{dataField: 'created_at', order: 'desc'}],
        insertRow: [
            {columns: ['child_id'], dataType: 'react-select', options: {source: 'database', name: 'children', value: 'child_id', labels: ['child_id', 'first_name', 'surname']}, mandatory: true},
            {columns: ['centre_id'], dataType: 'react-select', options: {source: 'database', name: 'centres', conditions: [{col: 'location_type', value: 'centre'}], value: 'centre_id', labels: ['centre_name']}, mandatory: true},
            {columns: ['approval_type'], dataType: 'react-select', options: {source: 'hardcoded', list: [{value: 'booking-approval-required', label: 'Require booking approval'}, {value: 'suspend-bookings', label: 'Suspend bookings'}]}, mandatory: true},
            {columns: ['approval_status'], dataType: 'react-select', options: {source: 'hardcoded', list: [{value: 'pending', label: 'Pending'}]}, mandatory: true},
            {columns: ['reason'], dataType: 'text'},
        ]
    },
    view_child_approvals: {
        uniqueSelectId: 'id',
        detailLinkingId: 'child_id',
        filterColumns: [{columns: ['child_name'], filterType: 'text'}, {columns: ['centre_name', 'approval_status', 'approval_type'], filterType: 'select'}],
        defaultSorted : [{dataField: 'created_at', order: 'desc'}],
        excludeColumns: ['id', 'group_id', 'centre_id', 'child_id', 'num_enrolments'],
        defaultFilters: [{columns: ['approval_type'], value: 'suspend-bookings'},{columns: ['approval_status'], value: 'pending'}],
    },
    view_bookings_pending_approval: {
        uniqueSelectId: 'booking_id',
        detailLinkingId: 'child_id',
        filterColumns: [{columns: ['first_name', 'surname'], filterType: 'text'}, {columns: ['centre_name', 'approval_status', 'approval_type'], filterType: 'select'}],
        defaultSorted : [{dataField: 'created_at', order: 'desc'}],
        excludeColumns: ['booking_id', 'group_id', 'centre_id', 'child_id'],
        defaultFilters: [{columns: ['approval_status'], value: 'pending'}],
    },
    child_enrolments: {
        uniqueSelectId: 'id',
        filterColumns: [{columns: ['child_id'], filterType: 'text'}],
        defaultSorted : [{dataField: 'child_id', order: 'asc'}],        
        
    },
    view_child_enrolments: {
        uniqueSelectId: 'id',
        excludeColumns: ['id', 'group_id', 'account_holder', 'service_crn', 'submitted_by', 'form_type'],
        filterColumns: [{columns: ['child_name', 'ccs_enrolment_id'], filterType: 'text'}, {columns: ['enrolment_status', 'header_status', 'centre_name'], filterType: 'select'}],
        defaultSorted : [{dataField: 'child_id', order: 'asc'}],        
    },
    view_non_confirmed_enrolments: {
        uniqueSelectId: 'id',
        excludeColumns: ['id', 'group_id', 'centre_id'],
        filterColumns: [{columns: ['child_name', 'ccs_enrolment_id'], filterType: 'text'}, {columns: ['enrolment_status', 'header_status', 'centre_name', 'arrangement_type', 'active_status', 'attendance_flag_last_year', 'attendance_flag_last_6_months', 'attendance_flag_last_3_months', 'attendance_flag_last_month', 'attendance_flag_last_fortnight'], filterType: 'select'}],
    },
    view_account_debt_and_attendance: {
        uniqueSelectId: 'account_id',
        excludeColumns: ['group_id'],
        filterColumns: [{columns: ['guardian_first_name', 'guardian_surname', 'email'], filterType: 'text'}, {columns: ['attendance_flag_last_year', 'attendance_flag_last_6_months', 'attendance_flag_last_3_months', 'attendance_flag_last_month', 'attendance_flag_last_fortnight'], filterType: 'select'}],
    },
    children_medical: {
        filterColumns: [{columns: ['child_id'], filterType: 'text'}],
        uniqueSelectId: 'child_id',
        excludeColumns: ['group_id', 'dental_address_house_number', 'dental_address_road', 'dental_address_postcode', 'dental_address_suburb', 'dental_address_state', 'dental_address_country', 'med_address_suburb', 'med_address_state', 'med_address_country'],
        editableFields: [{columns: ['court_orders_1', 'court_orders_2'], dataType: 'CHECKBOX'}],
        defaultSorted : [{dataField: 'child_id', order: 'asc'}],
        primaryKeys: ['group_id', 'child_id'],
        
    },
    roll: {
        uniqueSelectId: 'id',
        primaryKeys: ['id'],
        excludeColumns: ['group_id', 'p1', 'p2', 'p3', 'p4', 'p5', 'p6', 'p7', 'p8', 'p9', 'p10', 'p11', 'p12', 'p13', 'p14', 'p15', 'p16', 'p17', 'p18']
    },
    roll_event_log: {
        uniqueSelectId: 'id',
        filterColumns: [{columns: ['event', 'created_at'], filterType: 'text'}],
        
    },
    excursions: {
        uniqueSelectId: 'excursion_id',
        excludeColumns: ['group_id'],
        primaryKeys: ['excursion_id'],
        editableFields: [{columns: ['title', 'surcharge', 'start_date', 'session'], dataType: 'TEXT'}],
        filterColumns: [{columns: ['centre_id', 'start_date', 'session'], filterType: 'select'}],
        insertRow: [
            {columns: ['centre_id'], dataType: 'react-select', options: {source: 'database', name: 'centres', conditions: [{col: 'location_type', value: 'centre'}], value: 'centre_id', labels: ['centre_name']}, mandatory: true},
            {columns: ['session'], dataType: 'react-select', options: {source: 'globalObjectsArray', array: sessionTypes}, mandatory: true},
            // {columns: ['status'], dataType: 'react-select', options: {source: 'hardcoded', list: [{value: 'active', label: 'Active'}, {value: 'ceased', label: 'Ceased'}]}, mandatory: true},
            {columns: ['surcharge'], dataType: 'number'},
            {columns: ['title'], dataType: 'text'},
            {columns: ['start_date', 'end_date'], dataType: 'date'},
        ] ,       
    },
    excursion_detail: {
        uniqueSelectId: 'id',
        excludeColumns: ['group_id'],
        editableFields: [{columns: ['key', 'value'], dataType: 'TEXT'}],
        filterColumns: [{columns: ['key'], filterType: 'select'}, {columns: ['excursion_id'], filterType: 'text'}],
        insertRow: [
            {columns: ['excursion_id'], dataType: 'react-select', options: {source: 'database', name: 'excursions', value: 'excursion_id', labels: ['centre_id', 'start_date', 'session']}, mandatory: true},
            {columns: ['key', 'value'], dataType: 'text'},
        ],               
    },
    centres: {
        uniqueSelectId: 'centre_id',
        primaryKeys: ['centre_id', 'group_id'],
        defaultSorted: [{dataField: 'centre_id', order: 'asc'}],
        excludeColumns: ['group_id'],
        defaultFilters: [{columns: ['location_type'], value: 'centre'}],
        filterColumns: [{columns: ['location_type'], filterType: 'select'}],
        editableFields: [{columns: ['address_street_line_1', 'address_street_line_2', 'address_suburb', 'address_postcode', 'address_state', 'phone_number', 'email', 'default_educator_id', 'display_address', 'location_within_school', 'auth_person_id'], dataType: 'TEXT'}],
    },
    centre_capacities: {
        uniqueSelectId: 'centre_id+session',
        excludeColumns: ['centre_id+session']
    },
    form_submissions: {
        uniqueSelectId: 'form_id',
        defaultSorted: [{dataField: 'submitted_at', order: 'desc'}],
        excludeColumns: ['group_id'],
        filterColumns: [{columns: ['submitted_by'], filterType: 'text'}, {columns: ['status', 'ref_type'], filterType: 'select'}],
    },
    view_enrolment_forms_no_account: {
        uniqueSelectId: 'form_id',
        defaultSorted: [{dataField: 'submitted_at', order: 'desc'}],
        excludeColumns: ['group_id'],
        filterColumns: [{columns: ['submitted_by'], filterType: 'text'}, {columns: ['status', 'ref_type'], filterType: 'select'}],
    },
    failed_form_submissions: {
        uniqueSelectId: 'form_id',
        defaultSorted: [{dataField: 'submitted_at', order: 'desc'}],
        excludeColumns: ['group_id'],
        filterColumns: [{columns: ['submitted_by'], filterType: 'text'}],
    },
    view_filing_cabinet: {
        uniqueSelectId: 'id',
        defaultSorted: [{dataField: 'submitted_at', order: 'desc'}],
        excludeColumns: ['group_id', 'key'],
        filterColumns: [{columns: ['submitted_by'], filterType: 'text'}, {columns: ['category', 'type'], filterType: 'select'}],
    },
    view_crm_activities: {
        uniqueSelectId: 'id',
        excludeColumns: ['group_id'],
        filterColumns: [{columns: ['account_holder'], filterType: 'text'}, {columns: ['activity_description', 'status'], filterType: 'select'}],
        defaultFilters: [{columns: ['status'], value: 'pending'}]
    },
    view_permanent_bookings: {
        uniqueSelectId: 'id',
        excludeColumns: ['group_id', 'account_id'],
        filterColumns: [{columns: ['centre_id', 'session'], filterType: 'select'}, {columns: ['child_id'], filterType: 'text'}],
    },
    view_accounts_create_contact_xero: {
        uniqueSelectId: 'account_id',
        excludeColumns: ['xero_contact_id'],
        filterColumns: [{columns: ['first_name', 'surname',  'email'], filterType: 'text'}],
    },
    view_primary_contacts_info: {
        uniqueSelectId: 'account_id+centre+school',
        excludeColumns: ['xero_contact_id', 'account_id+centre+school'],
        filterColumns: [{columns: ['first_name', 'surname',  'email'], filterType: 'text'}, {columns: ['school'], filterType: 'select'}, {columns: ['centre'], filterType: 'multi-select'}],
    },
    view_accounts_in_debt: {
        uniqueSelectId: 'account_id+centre+school',
        excludeColumns: ['xero_contact_id', 'account_id+centre+school'],
        filterColumns: [{columns: ['first_name', 'surname',  'email'], filterType: 'text'}, {columns: ['centre', 'school'], filterType: 'select'}],
    },
    account_preference_codes: {
        uniqueSelectId: 'id',
    },
    account_preferences: {
        uniqueSelectId: 'id',
        excludeColumns: ['group_id'],
        filterColumns: [{columns: ['preference_code'], filterType: 'select'}, {columns: ['account_id'], filterType: 'text'}],
        insertRow: [
            {columns: ['account_id'], dataType: 'react-select', options: {source: 'database', name: 'view_accounts',  conditions: [], value: 'account_id', labels: ['primary_name', 'primary_email', 'preferred_contact', 'children_names']}, mandatory: true},
            {columns: ['preference_code'], dataType: 'react-select', options: {source: 'database', name: 'account_preference_codes', conditions: [], value: 'preference_code', labels: ['preference_desc']}, mandatory: true},
            {columns: ['preference'], dataType: 'react-select', options: {source: 'hardcoded', list: [{value: 'yes', label: 'Yes'}, {value: 'no', label: 'No'}]}, mandatory: true},
            {columns: ['preference_detail'], dataType: 'text'},
        ] ,            
    },
    all_contacts: {
        uniqueSelectId: 'person_id',
    },
    staff_details: {
        uniqueSelectId: 'person_id',
        primaryKeys: ['person_id', 'group_id'],
        editableFields: [{columns: ['first_name', 'surname', 'cl_person_id'], dataType: 'TEXT'}],
        filterColumns: [{columns: ['first_name', 'surname'], filterType: 'text'}],
    },
    staff_centres: {
        uniqueSelectId: 'person_id+centre_id',
        excludeColumns: ['person_id+centre_id'],
        filterColumns: [{columns: ['centre_id', 'person_id'], filterType: 'select'}],
    },
    emergency_contacts: {
        uniqueSelectId: 'contact_id',
        primaryKeys: ['group_id', 'contact_id']
    },
    form_approvals: {
        uniqueSelectId: 'form_id',
    },
    centre_sessions_calendar: {
        uniqueSelectId: 'date+centre_id',
        excludeColumns: ['date+centre_id'],
        filterColumns: [{columns: ['centre_id'], filterType: 'select'}],
    },
    variables: {
        uniqueSelectId: 'variable',
    },
    kiosks: {
        uniqueSelectId: 'centre_id',
        // insertRow: [
        //     {columns: ['kiosk_email'], dataType: 'react-select', options: {source: 'database', name: 'all_user_accounts', conditions: [{col: 'account_type', value: 'kiosk'}], value: 'email', labels: ['email']}, mandatory: true},
        //     {columns: ['centre_id'], dataType: 'react-select', options: {source: 'database', name: 'centres', conditions: [{col: 'location_type', value: 'centre'}], value: 'centre_id', labels: ['centre_name']}, mandatory: true}
        // ] ,         // commenting out as kiosk email is automatically added to kiosks table when role changed to kiosk and centre linked
    },
    kiosk_admins: {
        uniqueSelectId: 'staff_id+centre_id',
        primaryKeys: ['group_id', 'staff_id', 'centre_id'],
        excludeColumns: ['group_id', 'staff_id+centre_id', 'pin'],
        insertRow: [
            {columns: ['staff_id'], dataType: 'react-select', options: {source: 'database', name: 'view_staff_centres_details', conditions: [], value: 'person_id', labels: ['person_id', 'first_name', 'surname']}, mandatory: true},
            {columns: ['centre_id'], dataType: 'react-select', options: {source: 'database', name: 'centres', conditions: [{col: 'location_type', value: 'centre'}], value: 'centre_id', labels: ['centre_name']}, mandatory: true},
            {columns: ['pin'], dataType: 'text'},
        ] ,            
    },
    view_kiosk_admins: {
        uniqueSelectId: 'staff_id+centre_id',
        primaryKeys: ['group_id', 'staff_id', 'centre_id'],
        excludeColumns: ['group_id', 'staff_id+centre_id'],         
        filterColumns: [{columns: ['staff_email', 'staff_name'], filterType: 'text'}, {columns: ['centre_name'], filterType: 'select'}],
    },
    idp_group_access: {
        uniqueSelectId: 'idp+idp_group+api_route_allowed+api_method_allowed',
        excludeColumns: ['idp+idp_group+api_route_allowed+api_method_allowed'],
        filterColumns: [{columns: ['idp_group', 'api_route_allowed', 'api_method_allowed'], filterType: 'select'}],
    },
    role_access: {
        uniqueSelectId: 'role+entity+field+value+operator',
        excludeColumns: ['role+entity+field+value+operator'],
        filterColumns: [{columns: ['role', 'entity', 'field'], filterType: 'select'}],
    },
    user_roles: {
        uniqueSelectId: 'user_email+role',
        excludeColumns: ['user_email+role'],
    },
    pricing_discounts: {
        uniqueSelectId: 'id',
        filterColumns: [{columns: ['account_id'], filterType: 'text'}],
    },    
    pricing_rates: {
        uniqueSelectId: 'type+centre_id+session+age_group+start_date',
        excludeColumns: ['type+centre_id+session+age_group+start_date'],
        filterColumns: [{columns: ['centre_id', 'type', 'end_date'], filterType: 'select'}],
    },    
    pricing_session_hours: {
        uniqueSelectId: 'centre_id+session+start_date',
        excludeColumns: ['centre_id+session+start_date'],
        filterColumns: [{columns: ['centre_id', 'session', 'end_date'], filterType: 'select'}],
    },    
    view_pricing_session_hours: {
        uniqueSelectId: 'centre_id+session+start_date',
        excludeColumns: ['centre_id+session+start_date'],
        filterColumns: [{columns: ['centre_id', 'session', 'end_date', 'centre_name'], filterType: 'select'}],
    },    
    view_pricing_session_operational_hours: {
        uniqueSelectId: 'centre_id+session+start_date',
        excludeColumns: ['centre_id+session+start_date'],
        filterColumns: [{columns: ['centre_id', 'session', 'end_date', 'centre_name'], filterType: 'select'}],
    },    
    casual_bookings: {
        uniqueSelectId: 'booking_id',
        excludeColumns: ['group_id'],
        filterColumns: [{columns: ['centre_id', 'session'], filterType: 'select'}, {columns: ['child_id'], filterType: 'text'}],
    },    
    all_user_account_email_changes: {
        uniqueSelectId: 'id',
        excludeColumns: ['group_id'],
        filterColumns: [ {columns: ['new_email', 'old_email'], filterType: 'text'}],
    },    
    view_session_report_validation: {
        uniqueSelectId: 'billing_id',
        filterColumns: [{columns: ['first_name', 'billing_id'], filterType: 'text'}, {columns: ['centre_id', 'child_id'], filterType: 'select'}],
    },
    view_quicksight_attendance_data_filtered: {
        uniqueSelectId: 'date+centre_id+session+status',
        excludeColumns: ['date+centre_id+session+status', 'group_id', 'centre_id'],
        filterColumns: [{columns: ['centre_name', 'session', 'location_type', 'status'], filterType: 'select'}],
    },
    crm_account_notes: {
        uniqueSelectId: 'id',
        defaultSorted : [{dataField: 'date', order: 'desc'}]
    },
    view_centre_coord_child_details: {
        uniqueSelectId: 'ccs_enrolment_id',
    },
    view_staff_centres_details: {
        uniqueSelectId: 'person_id+centre_id',
        excludeColumns: ['group_id', 'person_id+centre_id'],
    },
    view_emergency_contacts: {
        uniqueSelectId: 'id',
        excludeColumns: ['group_id', 'id', 'centre_id'],
        filterColumns: [{columns: ['child_name', 'first_name', 'surname'], filterType: 'text'}, {columns: ['centre_name', 'relationship'], filterType: 'select'}],
    },
    view_emergency_contacts_account_level: {
        uniqueSelectId: 'person_id+relationship',
        excludeColumns: ['group_id', 'account_id', 'person_id+relationship', 'preferred_contact', 'additional_contact', 'email', 'nationality', 'language_spoken_at_home', 'crn', 'dob', 'guardian_id', 'contact_id', 'address_unit','address_building','address_house_number','address_road','address_postcode','address_suburb','address_state','address_country'],
        filterColumns: [{columns: ['first_name', 'surname'], filterType: 'text'}, {columns: ['relationship', 'contact_type'], filterType: 'select'}],
    },
    view_roll_activity_log: {
        uniqueSelectId: 'id',
        excludeColumns: ['id', 'group_id', 'account_id', 'child_id', 'centre_id', 'surname', 'date_iso'],
        filterColumns: [{columns: ['centre_name', 'status', 'session', 'date', 'location_type'], filterType: 'select'}],
        defaultFilters: [{columns: ['location_type'], value: 'centre'}],
    },
    user_feedback_responses: {
        uniqueSelectId: 'user_feedback_id',
        excludeColumns: ['centre_id'],
        // filterColumns: [{columns: ['centre_name', 'status', 'session', 'date', 'location_type'], filterType: 'select'}],
        // defaultFilters: [{columns: ['location_type'], value: 'centre'}],
    },
    view_feedback_form_responses: {
        uniqueSelectId: 'user_feedback_id',
        excludeColumns: ['centre_id', 'process'],
        filterColumns: [{columns: ['account_id', 'person_id', 'centre_name', 'question', 'rating'], filterType: 'select'}],
        
    },
    view_end_schedule_responses: {
        uniqueSelectId: 'id',
        excludeColumns: ['centre_id', 'id'],
        filterColumns: [{columns: ['account_id', 'person_id', 'centre_name', 'feedback_option', 'recommend_active_oosh', 'contact_parent'], filterType: 'select'}],

    },
    view_roll_sessions_contacts: {
        uniqueSelectId: 'id',
        // excludeColumns: ['centre_id', 'id'],
        filterColumns: [{columns: ['centre_name', 'location_type', 'date', 'session', 'first_name', 'surname', 'guardian_first_name', 'guardian_surname', 'preferred_contact'], filterType: 'select'}],
    },
    feedback_form_questions: {
        uniqueSelectId: 'question_id',
        filterColumns: [{columns: ['question', 'active', 'process'], filterType: 'select'}],
        editableFields: [{columns: ['question', 'process'], dataType: 'TEXT'}, {columns: ['active'], dataType: ['CHECKBOX']}],
        primaryKeys: ['question_id']

    },
    
    
   
}

export const personnelRoleTypes = {
    'MANCON': 'Provider Management or Control',
    'OPERAT': 'Day-to-day operation of the Service ',
    'CONTAC': 'Service Contact',
    'FDCEDU':'Educator', 	
};

export const personnelPositions = {
    'Z7': 'Chairperson' ,
    'Z8':  'Chief Executive Officer',
    'Z9':  'Child Care Service Director',
    'Z10':  'Company Director' ,
    'Z11':  'Company Financial Officer',
    'Z12': 'Company Secretary' ,
    'Z13':   'Coordinator',
    'Z14': 'Executive Officer' ,
    'Z15':  'General Manager' ,
    'Z16':  'Manager' ,
    'Z17': 'Nominated Supervisor' ,
    'Z18':  'Operator',
    'Z19': 'President' ,
    'Z20': 'Principal' ,
    'Z21': 'Program Manager' ,
    'Z22': 'Treasurer' ,
    'Z23':   'Other',	
};

export const supportingDocumentTypes = {
    'AM0001': 'AM0001 - Partnership agreement/deed between all members of the Partnership', 
    'AM0002': 'AM0002 - Rules/Constitution of Association', 
    'AM0003': 'AM0003 - Financial statement for previous financial year', 
    'AM0004': 'AM0004 - Annual General Meeting Minutes', 
    'AM0005': 'AM0005 - List of elected office bearers', 
    'AM0006': 'AM0006 - Letters Patent (Provider)', 
    'AM0007': 'AM0007 - Letters Patent (Personnel)', 
    'AM0008': 'AM0008 - Evidence from your state or territory regulatory authority which states the Service is ‘out of scope with the national law’', 
    'AM0009': 'AM0009 - Service Approval issued by the State/Territory Regulatory Authority', 
    'AM0010': 'AM0010 - Evidence of place of business', 
    'AM0011': 'AM0011 - Historical and Current Company Extract Report from ASIC', 
    'AM0012': 'AM0012 - List of Directors', 
    'AM0013': 'AM0013 - Certified copy of the rules as registered', 
    'AM0014': 'AM0014 - Board appointed person', 
    'AM0015': 'AM0015 - Evidence that the person has authority to apply', 
    'AM0016': 'AM0016 - Extract of relevant legislation', 
    'AM0017': 'AM0017 - Governing documents outlining the relevant rules and how the governing body will be run', 
    'AM0018': 'AM0018 - Certified copy of an Australian National Police Criminal History Check', 
    'AM0019': 'AM0019 - Extract from the National Personal Insolvency Index Bankruptcy search', 
    'AM0020': 'AM0020 - Current and historical personal name extract search of the Australian Securities and Investments Commission', 
    'AM0021': 'AM0021 - Copy of the Trust Deed', 
    'AM0022': 'AM0022 - Evidence of a signed agreement with the ‘External Management’ or attach any additional information', 
    'AM0023': 'AM0023 - Details of governance arrangements', 
    'AM0024': 'AM0024 - Evidence External Administrator appointed', 
    'AM0025': 'AM0025 - Copy of notice to parents/guardians regarding service closure', 
    'AM0026': 'AM0026 - Evidence of Service name change', 
    'AM0027': 'AM0027 - Evidence of Provider name change', 
    'AM0028': 'AM0028 - CCS related document (A generic document upload or when further information is requested – for Provider) ', 
    'AM0029': 'AM0029 - CCS related document (A generic document upload or when further information is requested – for Service) ', 
    'AM0030': 'AM0030 - CCS related document (A generic document upload or when further information is requested – for Personnel) ', 
    'ACC001': 'ACC001 - Supporting evidence for change to ACCS Child Wellbeing cap (non-mandatory)'	
};

export const ACCSDocTypes = {
    'ACC002': 'ACC002 - ACCS ChildWellBeing Evidence',
    'ACC003': 'ACC003 - State/Territory notice (204K notice)',
    'ACC004': 'ACC004 - ACCS ChildWellBeing other supporting documents',
    'ACC005': 'ACC005 - Advise child no longer at risk (67FC) ',
    'ACC006': 'ACC006 - CCS related document (A generic document upload or when further information is requested – for Provider) ',
    'ACC007': 'ACC007 - CCS related document (A generic document upload or when further information is requested – for Service) ',
    'ACC008': 'ACC008 - Evidence of exceptional circumstance',
};

export const ACCSRiskReasons = [
    
    {'value': 'COSTB', 'label': 'Under care and protection of a State/Territory child protection legislation'},
    {'value': 'ABUSED', 'label': 'Suffering harm due to experiencing physical, emotional or psychological abuse'},
    {'value': 'SABUSE', 'label': 'Is being subjected to sexual abuse'},
    {'value': 'DOMVIO', 'label': 'Suffering as a result of exposure to domestic or family violence'},
    {'value': 'NEGLEC', 'label': 'Suffering, or is at risk of suffering harm caused by neglect'},
    {'value': 'ABPAST', 'label': 'Suffered harm in the past due to experiencing physical, emotional or psychological abuse'},
    {'value': 'SAPAST', 'label': 'Suffered harm in the past due to being subjected to sexual abuse'},
    {'value': 'VIPAST', 'label': 'Suffered harm in the past due to exposure to domestic or family violence'},
    {'value': 'NEGPST', 'label': 'Suffered harm in the past due to experiencing neglect'},
    
];

export const ACCSStateTerritoryBodyTypes = [

    {'value': 'CODE01','label': 'Parenting Assist, Family Support Program'},
    {'value': 'CODE02','label': 'Conflict/Separation/Mediation services'},
    {'value': 'CODE03','label': 'Child and Maternal Health Services'},
    {'value': 'CODE04','label': 'Drug/Substance Abuse Services'},
    {'value': 'CODE05','label': 'Community Health Services'},
    {'value': 'CODE06','label': 'Domestic Violence/Rape Victim Support '},
    {'value': 'CODE07','label': 'Homelessness, Crisis or Public Housing'},
    {'value': 'CODE08','label': 'Financial/Gambling Counselling Services'},
    {'value': 'CODE09','label': 'Aboriginal Torres Strait Services'},
    {'value': 'CODE10','label': 'School Education Related Services'},
    {'value': 'CODE11','label': 'Other Early Intervention Services'},
    {'value': 'CODE12','label': 'Child Protection Agency '},
     
        
];

export const ACCSExceptionalCircumstanceReasons = [
    
    {'value': 'BEYOND', 'label': 'Delay in evidence beyond Provider’s control'},
    {'value': 'OTHER', 'label': 'Other'},
    
];
export const ACCSExtensionReasons = [
    
    {'value': 'LONGPO', 'label': 'The child is on long term protection order'},
    {'value': 'FOSKIN', 'label': 'The child is in formal foster care/kinship care'},
        
];

export const debtDocTypes = {
    'DBT001': 'Debt evidence of alternative payment arrangement ( Provider)'
}

// export const generalDocTypes = ['Immunisation Record', 'Medical Documents', 'Court Orders', 'Other']
export const generalDocTypes = {
    'Immunisation Record': 'Immunisation Record', 
    'Medical Documents': 'Medical Documents',
    'Court Orders': 'Court Orders',
    'Other': 'Other'
}

export const ccsMimeTypeTranslation = {
    'image/jpeg': 'jpg',
    'image/png': 'png',
    'application/pdf': 'pdf',
    'image/tiff': 'tiff'
};



export const ausStates = ['NSW', 'VIC', 'SA', 'NT', 'TAS', 'WA', 'QLD', 'ACT'];

// export const enrolmentArrangementTypes = ['CWA', 'PEA', 'RA', 'MIGRATION', 'NO CENTRELINK SUBMISSION'];
export const enrolmentArrangementTypes = ['CWA', 'PEA', 'RA', 'MIGRATION'];
// export const enrolmentArrangementTypes = ['CWA', 'PEA', 'RA'];
export const enrolmentArrangementTypeOptions = [
    {value: 'CWA', label: 'CWA'}, 
    {value: 'PEA', label: 'PEA'},
    {value: 'RA', label: 'RA'},
    {value: 'MIGRATION', label: 'MIGRATION'}
];

export const enrolmentNoticeTypes = [{value: '200A', label: 'INITIAL - 200A'}, {value: '200D', label: 'UPDATE - 200D'}];

export const reasonsForPEA = ['FOSKIN', 'NOCARE'];

export const CrmAccountNoteTypes = [{value: 'general', label: 'General'}, {value: 'customer-check-in', label: 'Customer Check-In'}, {value: 'other', label: 'Other'}];


export const childGuardianAuthTypes = [
    {value: 'collection', label: 'Collection / pick-up of my child'},
    {value: 'emergency', label: 'Be contacted in the case of an emergency'},
    {value: 'medical', label: 'Give authorisations for medical care'},
    {value: 'excursion' , label: 'Give authorisations for my child to attend excursions'},
    {value: 'transport', label: 'Give authorisations for transporting my child from the centre'},
];


export const reservedRollGroupNames = ['All Groups', 'No Group'];

export const creditNoteDescriptionOptions = [{value: 'BASC_VOUCHER', label: 'BASC Voucher'}, {value: 'MANUAL_ACCOUNT_CREDIT', label: 'Manual Account Credit'}];
export const invoiceDescriptionOptions = [{value: 'MANUAL_ACCOUNT_DEBIT', label: 'Manual Account Debit'}];

export const xeroInvoiceStatusTypes = [{value: 'DRAFT', label: 'Draft'}, {value: 'AUTHORISED', label: 'Authorised'}];

// export const accountDataGroupings = {
//     'Primary Guardian Details': ['pg_first_name', 'pg_surname', 'pg_gender', 'pg_preferred_contact', 'pg_language', 'pg_crn', 'pg_nationality', 'pg_address', 'pg_dob'],
//     'Secondary Guardian Details': ['sg_first_name', 'sg_surname', 'sg_gender', 'sg_preferred_contact', 'sg_language', 'sg_nationality', 'sg_address',  'sg_dob'],
//     'Emergency Contact Details': ['ec_first_name','ec_surname','ec_address','ec_contact_number','ec_relationship'],
//     'Child Details': ['c_first_name', 'c_surname', 'c_gender', 'c_country_of_birth', 'c_language_spoken_at_home', 'c_crn', 'c_current_school_year', 'c_cultural_background', 'c_school_child_attends', 'c_dob'],
//     'Child Medical Details': ['c_med_prac_name', 'c_med_prac_phone', 'c_med_address', 'c_diagnosed_condition_detail', 'c_dietary_restrictions_detail', 'c_medicare_number', 'c_medicare_child_irn', 'c_medicare_expiry_date', 'c_diagnosed_condition', 'c_dietary_restrictions', 'c_ambulance_cover', 'c_immunisations', 'c_court_orders_1', 'c_court_orders_2'],
//     'Relationship of Guardians to Children': ['pg_relationship', 'sg_relationship'],
//     'Authorisations Between Child and Guardians': ['pg_collection' ,'pg_emergency' ,'pg_medical' ,'pg_excursion' ,'pg_transport' ,'sg_collection' ,'sg_emergency' ,'sg_medical' ,'sg_excursion' ,'sg_transport'],
//     'Authorisations Between Child and Emergency Contacts': ['ec_collection' ,'ec_emergency' ,'ec_medical' ,'ec_excursion' ,'ec_transport'],
//     'Authorisations Between Child and Provider/Centre': ['auth_evacuation', 'auth_transport', 'auth_sunscreen', 'auth_panadol', 'auth_ambulance', 'auth_first_aid', 'auth_liaise', 'auth_medical', 'photo_permissions_internal', 'photo_permissions_external' ],
//     'Documents': ['immunisation_docs', 'medical_docs', 'court_docs']
// };

export const accountDataGroupings = {
    'Guardian Details': ['pg_first_name', 'pg_surname', 'pg_gender', 'pg_preferred_contact', 'pg_language', 'pg_crn', 'pg_nationality', 'pg_address', 'pg_dob', 'pg_child_authorisations', 'pg_collection' ,'pg_emergency' ,'pg_medical' ,'pg_excursion' ,'pg_transport', 'pg_relationship', 'sg_first_name', 'sg_surname', 'sg_gender', 'sg_preferred_contact', 'sg_language', 'sg_nationality', 'sg_address',  'sg_dob', 'sg_child_authorisations', 'sg_collection' ,'sg_emergency' ,'sg_medical' ,'sg_excursion' ,'sg_transport', 'sg_relationship'],
    'Emergency Contact Details': ['ec_first_name','ec_surname','ec_address','ec_contact_number','ec_relationship', 'ec_child_authorisations', 'ec_collection' ,'ec_emergency' ,'ec_medical' ,'ec_excursion' ,'ec_transport'],
    'Child Details': ['c_first_name', 'c_surname', 'c_gender', 'c_country_of_birth', 'c_language_spoken_at_home', 'c_crn', 'c_current_school_year', 'c_cultural_background', 'c_school_child_attends', 'c_dob', 'c_address', 'c_med_prac_name', 'c_med_prac_phone', 'c_med_address', 'c_diagnosed_condition_detail', 'c_dietary_restrictions_detail', 'c_medicare_number', 'c_medicare_child_irn', 'c_medicare_expiry_date', 'c_diagnosed_condition', 'c_dietary_restrictions', 'c_ambulance_cover', 'c_immunisations', 'c_court_orders_1', 'c_court_orders_2', 'auth_evacuation', 'auth_transport', 'auth_sunscreen', 'auth_panadol', 'auth_ambulance', 'auth_first_aid', 'auth_liaise', 'auth_medical', 'photo_permissions_internal', 'photo_permissions_external', 'immunisation_docs', 'medical_docs', 'court_docs'],
};


export const childGroupAuthDescriptions = {
    'photoPermissionsInternal': 'I give permission for photos/videos of my child to be posted on internal sites, such as the Active OOSH App, which is visible to other families that attend the service',
    'photoPermissionsExternal': 'I give permission for photos/videos of my child to be posted on service websites, social media and other public sites',
    'transport': 'I authorise team members of the education and care service (centre) to take my child from the service (centre) at times of excursion or regular transportation e.g to and from school. I understand that the service (centre) will follow the Delivery and Collection of Children, Transportation of Children, Excursion/Incursion and Supervision Policies and ensure that written permission has been obtained from myself in accordance with the policy guidelines',
    'evacuation': 'I authorise my child to participate in evacuation drills that may require my child to go to the designated meeting place that is outside of the education and care service',
    'medical': 'I authorise the nominated supervisor or other educator at the service to seek medical treatment from a registered medical or dental practitioner or service in the event of an emergency',
    'ambulance': 'I authorise transport of my child in an ambulance in the event of an emergency. a nominated supervisor or other educator will accompany the child',
    'firstAid': 'I authorise that if the child is diagnosed with asthma or anaphylaxis and an emergency occurs, the nominated supervisor or other educators may administer emergency first aid without making contact. educators will notify the child’s parents and/or emergency services as soon as possible. (education and care services national regulations regulation 94)',
    'liaise': 'I authorise ongoing open communication & exchange of information between educators & school staff in regards to my child & family',
    'panadol': "I authorise the nominated supervisor or other educator at the service to administer paracetamol in accordance with the centre's relevant policies and procedures. i understand first aid measures are the preferred method of fever/illness assistance. in the event that an emergency dose of panadol is required (as per centre policy guidelines) i understand that i will be required to collect my child immediately and seek further medical assistance. i understand that panadol (paracetamol) will only be administered as a last resort and emergency services (ambulance) will be contacted should my child's condition worsen",
    'sunscreen': "I authorise the nominated supervisor or other educator at the service to apply sunscreen to my child. i understand that i must provide and clearly label sunscreen for my child if they have allergies or reactions to the service's supplied sunscreen",
}

export const childGuardianRelationshipTypes = [
    { "label": "Mother"},
    { "label": "Father"},
    { "label": "Aunt"},
    { "label": "Brother"},
    { "label": "Carer"},
    { "label": "Cousin"},
    { "label": "Foster Father"},
    { "label": "Foster Grandparent"},
    { "label": "Foster Mother"},
    { "label": "Foster Parent"},
    { "label": "Friend"},
    { "label": "Godparent"},
    { "label": "Grandparent"},
    { "label": "Guardian"},
    { "label": "Neighbour"},
    { "label": "Other"},
    { "label": "Parent"},
    { "label": "Relative"},
    { "label": "Sister"},
    { "label": "Social Worker"},
    { "label": "Step Parent"},
    { "label": "Uncle"}
]

export const schoolYears = [
    {label: 'Kindy Transition', value: 'pre-kindy'},
    {label: 'Kindy', value: 'kindy'},
    {label: 'Year 1', value: '1'},
    {label: 'Year 2', value: '2'},
    {label: 'Year 3', value: '3'},
    {label: 'Year 4', value: '4'},
    {label: 'Year 5', value: '5'},
    {label: 'Year 6', value: '6'},
]

export const stripeProducts = [
    {label: 'One-Off - Custom Amount', paymentType: 'payment',  type: 'stripePayWhatYouWant'},
    {label: '$25/Week Payment Plan', paymentType: 'subscription', type: 'stripeRecurring25'},
    {label: '$50/Week Payment Plan', paymentType: 'subscription', type: 'stripeRecurring50'},
    {label: '$75/Week Payment Plan', paymentType: 'subscription', type: 'stripeRecurring75'},
    {label: '$100/Week Payment Plan',paymentType: 'subscription', type: 'stripeRecurring100'},
];

export const emailVariables = [
    {name: 'first_name', table: 'guardians', column: 'first_name', primaryKey: 'guardian_id', label: "Guardian's First Name", syntax: "{first_name}"},
    {name: 'surname', table: 'guardians', column: 'surname', primaryKey: 'guardian_id', label: "Guardian's Surname", syntax: "{surname}"},
    {name: 'email', table: 'guardians', column: 'email', primaryKey: 'guardian_id', label: "Guardian's Email", syntax: "{email}"},
    {name: 'outanding_balance', table: 'accounts', column: 'outanding_balance', primaryKey: 'account_id', label: "Outstanding Balance", syntax: "{outstanding_balance}"},
];